import {DateTime} from 'luxon';
import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {BaseModel, BaseModelDeserializer} from '../common';

export interface TFacilityMapId {
  readonly globalId: string;
  readonly lastEditDate: string;
  readonly datasyncId: string;
}

/**
 * Compound Key for specific version of a UASFM Tile.
 *
 * @param globalId
 * @param lastEditDate
 */
export class FacilityMapId implements BaseModel {
  readonly globalId?: string;
  readonly lastEditDate?: DateTime;
  readonly datasyncId?: string;

  constructor(globalId?: string, lastEditDate?: DateTime, datasyncId?: string) {
    this.globalId = globalId;
    this.lastEditDate = lastEditDate;
    this.datasyncId = datasyncId;
  }
}

export class FacilityMapIdUtil implements BaseModelDeserializer<TFacilityMapId, FacilityMapId>{
  deserialize(raw: unknown): Result<FacilityMapId> {
    if (!isObject(raw)) return fail('Invalid type for Facility Map ID');

    let globalId: string | undefined = undefined;
    if (('globalId' in raw) && !isEmpty(raw.globalId)) {
      if (typeof raw.globalId !== 'string') return fail('Invalid Facility Map ID global ID value');
      globalId = raw.globalId;
    }

    let lastEditDate: DateTime | undefined = undefined;
    if (('lastEditDate' in raw) && !isEmpty(raw.lastEditDate)) {
      if (typeof raw.lastEditDate !== 'string') return fail('Invalid Facility Map ID last edit date value');
      lastEditDate = DateTime.fromISO(raw.lastEditDate);
      if (!lastEditDate.isValid) return fail('Invalid Facility Map ID last edit date value');
    }

    let datasyncId: string | undefined = undefined;
    if (('datasyncId' in raw) && !isEmpty(raw.datasyncId)) {
      if (typeof raw.datasyncId !== 'string') return fail('Invalid Facility Map ID datasync ID value');
      datasyncId = raw.datasyncId;
    }

    return some(new FacilityMapId(globalId, lastEditDate, datasyncId));
  }

  deserializeArray(raw: unknown): Result<FacilityMapId[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for Facility Map ID array');
    const results: FacilityMapId[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_FACILITY_MAP_ID_UTIL = new FacilityMapIdUtil();
