import {BaseEnumDeserializer} from '../../common';
import {fail, Result, some} from '../../../utils';

export enum AirspaceClassType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  G = 'G',
  Other = 'Other'
}

export class AirspaceClassTypeUtil implements BaseEnumDeserializer<AirspaceClassType, AirspaceClassType> {
  deserialize(raw: unknown): Result<AirspaceClassType> {
    if (typeof raw !== 'string') return fail('Invalid type for airspace class type');

    switch (raw.toUpperCase()) {
      case 'A':
        return some(AirspaceClassType.A);
      case 'B':
        return some(AirspaceClassType.B);
      case 'C':
        return some(AirspaceClassType.C);
      case 'D':
        return some(AirspaceClassType.D);
      case 'E':
        return some(AirspaceClassType.E);
      case 'G':
        return some(AirspaceClassType.G);
      case 'Other':
        return some(AirspaceClassType.Other);
      default:
        return fail(`Invalid airspace class type: ${raw}`);
    }
  }
}

export const DEFAULT_AIRSPACE_CLASS_TYPE_UTIL = new AirspaceClassTypeUtil();
