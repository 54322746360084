import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {BaseModel, BaseModelDeserializer} from '../common';

export interface IStadium {
  readonly name?: string;
  readonly city?: string;
  readonly state?: string;
}

export interface TStadium {
  readonly NAME?: string;
  readonly CITY?: string;
  readonly STATE?: string;
}

export class Stadium implements IStadium, BaseModel {
  readonly name?: string;
  readonly city?: string;
  readonly state?: string;

  constructor(values: IStadium) {
    this.name = values.name;
    this.city = values.city;
    this.state = values.state;
  }
}

export class StadiumUtil implements BaseModelDeserializer<TStadium, Stadium> {
  deserialize(raw: unknown): Result<Stadium> {
    if (isEmpty(raw)) return fail('No data supplied for stadium');
    if (!isObject(raw)) return fail('Invalid data supplied for stadium');

    let name: string | undefined = undefined;
    if (('NAME' in raw) && !isEmpty(raw.NAME)) {
      if (typeof raw.NAME !== 'string') return fail('Invalid stadium name value');
      name = raw.NAME;
    }

    let city: string | undefined = undefined;
    if (('CITY' in raw) && !isEmpty(raw.CITY)) {
      if (typeof raw.CITY !== 'string') return fail('Invalid city value');
      city = raw.CITY;
    }

    let state: string | undefined = undefined;
    if (('STATE' in raw) && !isEmpty(raw.STATE)) {
      if (typeof raw.STATE !== 'string') return fail('Invalid state value');
      state = raw.STATE;
    }

    return some(new Stadium({
      name,
      city,
      state
    }));
  }

  deserializeArray(raw: unknown): Result<Stadium[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for stadium array');
    const results: Stadium[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_STADIUM_UTIL = new StadiumUtil();
