import {BaseModel, BaseModelDeserializer} from '../common';
import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {DateTime} from 'luxon';

export interface ITfrData {
  readonly id?: string,
  readonly url?: string,
  readonly timeRange?: TfrTimeRange,
  readonly date?: string,
  readonly facility?: string,
  readonly state?: string,
  readonly type?: string,
  readonly description?: string
}

export interface TTfrData {
  readonly id?: string,
  readonly url?: string,
  readonly timeRange?: TTfrTimeRange,
  readonly date?: string,
  readonly facility?: string,
  readonly state?: string,
  readonly type?: string,
  readonly description?: string,
}

export class TfrData implements ITfrData, BaseModel {
  readonly id?: string;
  readonly url?: string;
  readonly timeRange?: TfrTimeRange;
  readonly date?: string;
  readonly facility?: string;
  readonly state?: string;
  readonly type?: string;
  readonly description?: string;

  constructor(values: TfrData) {
    this.id = values.id;
    this.url = values.url;
    this.timeRange = values.timeRange;
    this.date = values.date;
    this.facility = values.facility;
    this.state = values.state;
    this.type = values.type;
    this.description = values.description;
  }
}

export class TfrDataUtil implements BaseModelDeserializer<TTfrData, TfrData> {
  deserialize(raw: unknown): Result<TfrData> {
    if (isEmpty(raw)) return fail('No data supplied for TFR');
    if (!isObject(raw)) return fail('Invalid data supplied for TFR');

    let id: string | undefined = undefined;
    if (('id' in raw) && !isEmpty(raw.id)) {
      if (typeof raw.id !== 'string') return fail('Invalid TFR data ID value');
      id = raw.id;
    }

    let url: string | undefined = undefined;
    if (('url' in raw) && !isEmpty(raw.url)) {
      if (typeof raw.url !== 'string') return fail('Invalid TFR data URL value');
      url = raw.url;
    }

    let timeRange: any | undefined = undefined;
    if (('timeRange' in raw) && !isEmpty(raw.timeRange)) {
      const timeRangeResult = DEFAULT_TFR_TIME_RANGE_UTIL.deserialize(raw.timeRange);
      if (timeRangeResult.type === 'error') return fail(timeRangeResult.message);
      timeRange = timeRangeResult.value;
    }

    let date: string | undefined = undefined;
    if (('date' in raw) && !isEmpty(raw.date)) {
      if (typeof raw.date !== 'string') return fail('Invalid TFR data date value');
      date = raw.date;
    }

    let facility: string | undefined = undefined;
    if (('facility' in raw) && !isEmpty(raw.facility)) {
      if (typeof raw.facility !== 'string') return fail('Invalid TFR data facility value');
      facility = raw.facility;
    }

    let state: string | undefined = undefined;
    if (('state' in raw) && !isEmpty(raw.state)) {
      if (typeof raw.state !== 'string') return fail('Invalid TFR data state value');
      state = raw.state;
    }

    let type: string | undefined = undefined;
    if (('type' in raw) && !isEmpty(raw.type)) {
      if (typeof raw.type !== 'string') return fail('Invalid TFR data type value');
      type = raw.type;
    }

    let description: string | undefined = undefined;
    if (('description' in raw) && !isEmpty(raw.description)) {
      if (typeof raw.description !== 'string') return fail('Invalid TFR data description value');
      description = raw.description;
    }

    return some(new TfrData({
      id,
      url,
      timeRange,
      date,
      facility,
      state,
      type,
      description
    }));
  }

  deserializeArray(raw: unknown): Result<TfrData[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for TFR data array');
    const results: TfrData[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_TFR_DATA_UTIL = new TfrDataUtil();

export interface TTfrTimeRange {
  readonly timeIssued?: string,
  readonly timeEffective?: string,
  readonly  timeExpires?: string
}

export class TfrTimeRange implements BaseModel {
  readonly timeIssued?: DateTime;
  readonly timeEffective?: DateTime;
  readonly timeExpires?: DateTime;

  constructor(timeIssued?: DateTime, timeEffective?: DateTime, timeExpires?: DateTime) {
    this.timeIssued = timeIssued;
    this.timeEffective = timeEffective;
    this.timeExpires = timeExpires;
  }
}

export class TfrTimeRangeUtil implements BaseModelDeserializer<TTfrTimeRange, TfrTimeRange> {
  deserialize(raw: unknown): Result<TfrTimeRange> {
    if (isEmpty(raw)) return fail('No data supplied for TFR time range');
    if (!isObject(raw)) return fail('Invalid data supplied for TFR time range');

    let timeIssued: DateTime | undefined = undefined;
    if (('timeIssued' in raw) && !isEmpty(raw.timeIssued)) {
      if (typeof raw.timeIssued !== 'string') return fail('Invalid TFR time issued value');
      timeIssued = DateTime.fromISO(raw.timeIssued);
      if (!timeIssued.isValid) return fail('Invalid TFR time issued value');
    }

    let timeEffective: DateTime | undefined = undefined;
    if (('timeEffective' in raw) && !isEmpty(raw.timeEffective)) {
      if (typeof raw.timeEffective !== 'string') return fail('Invalid TFR time effective value');
      timeEffective = DateTime.fromISO(raw.timeEffective);
      if (!timeEffective.isValid) return fail('Invalid TFR time effective value');
    }

    let timeExpires: DateTime | undefined = undefined;
    if (('timeExpires' in raw) && !isEmpty(raw.timeExpires)) {
      if (typeof raw.timeExpires !== 'string') return fail('Invalid TFR time expires value');
      timeExpires = DateTime.fromISO(raw.timeExpires);
      if (!timeExpires.isValid) return fail('Invalid TFR time expires value');
    }

    return some(new TfrTimeRange(timeIssued, timeEffective, timeExpires));
  }
}

export const DEFAULT_TFR_TIME_RANGE_UTIL = new TfrTimeRangeUtil();
