import {fail, Result, some} from '../../../utils';
import {BaseEnumDeserializer} from '../../common';

export enum SuaTypeCode {
  /**
   * Alert areas
   */
  A = 'A',
  /**
   * Controlled firing areas (CFAs)
   */
  CFA = 'CFA',
  /** ? **/
  D = 'D',
  /**
   * Military operation areas (MOAs)
   */
  MOA = 'MOA',
  /**
   * Prohibited areas
   */
  P = 'P',
  /**
   * Restricted areas
   */
  R = 'R',
  /**
   * Warning areas
   */
  W = 'W'
}

export class SuaTypeCodeUtil implements BaseEnumDeserializer<SuaTypeCode, SuaTypeCode> {
  deserialize(raw: unknown): Result<SuaTypeCode> {
    if (typeof raw !== 'string') return fail('Invalid type for SUA type code');

    switch (raw.toUpperCase()) {
      case 'A':
        return some(SuaTypeCode.A);
      case 'CFA':
        return some(SuaTypeCode.CFA);
      case 'D':
        return some(SuaTypeCode.D);
      case 'MOA':
        return some(SuaTypeCode.MOA);
      case 'P':
        return some(SuaTypeCode.P);
      case 'R':
        return some(SuaTypeCode.R);
      case 'W':
        return some(SuaTypeCode.W);
      default:
        return fail(`Invalid SUA type code: ${raw}`);
    }
  }
}

export const DEFAULT_SUA_TYPE_CODE_UTIL = new SuaTypeCodeUtil();
