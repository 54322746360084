import {
  Airport,
  AirspaceClassType,
  BaseModel,
  BaseModelDeserializer,
  DEFAULT_AIRPORT_UTIL,
  DEFAULT_AIRSPACE_CLASS_TYPE_UTIL,
  DEFAULT_FACILITY_MAP_ID_UTIL,
  DEFAULT_LAANC_DENIAL_REASON_CODE_UTIL,
  DEFAULT_LAANC_POC_UTIL,
  DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL,
  DEFAULT_LAANC_SUBMISSION_STATE_UTIL,
  DEFAULT_LAANC_SUBMISSION_TYPE_UTIL,
  FacilityMapId,
  fail,
  isEmpty,
  isObject,
  LaancDenialReasonCode,
  LaancPoc,
  LaancSubmissionCategory,
  LaancSubmissionState,
  LaancSubmissionType,
  Result,
  some,
  TAirport,
  TFacilityMapId,
  TLaancPoc
} from '@ax-uss-ui/common';
import {DateTime} from 'luxon';
import {EntityVolume4d} from '../gen/utm';
import {IEntityVolume4d} from '../gen/transport/utm';
import {Parser} from '../utm/parser/OperationParser';

export interface TLaancSubmissionEntry {
  readonly submissionId: string,
  readonly operatorIntentId: string,
  readonly operationId: string,
  readonly referenceCode: string,
  readonly timeSubmitted: string,
  readonly timeUpdated: string,
  readonly expiresAt: string,
  readonly type: LaancSubmissionType,
  readonly category: LaancSubmissionCategory,
  readonly state: LaancSubmissionState,
  readonly uasfmIds: TFacilityMapId[],
  readonly airspaceClasses: AirspaceClassType[],
  readonly faaFacilityCode: string,
  readonly authorizingAirport: TAirport,
  readonly userId: string,
  readonly operationName: string,
  readonly poc: TLaancPoc,
  readonly volume: IEntityVolume4d,
  readonly maximumAltitudeAgl: number,
  readonly deleted: boolean,
  readonly canDelete: boolean,
  readonly canCancel: boolean,
  readonly registrationNumber?: string,
  readonly denialReason?: string,
  readonly denialReasonCode?: LaancDenialReasonCode,
  readonly denialReasonCodeText?: string,
}

interface ILaancSubmissionEntry {
  readonly submissionId: string,
  readonly operatorIntentId: string,
  readonly operationId: string,
  readonly referenceCode: string,
  readonly timeSubmitted: Date,
  readonly timeUpdated: DateTime,
  readonly expiresAt: DateTime,
  readonly type: LaancSubmissionType,
  readonly category: LaancSubmissionCategory,
  readonly state: LaancSubmissionState,
  readonly uasfmIds: FacilityMapId[],
  readonly airspaceClasses: AirspaceClassType[],
  readonly faaFacilityCode: string,
  readonly authorizingAirport: Airport,
  readonly userId: string,
  readonly operationName: string,
  readonly poc: LaancPoc,
  readonly volume: EntityVolume4d,
  readonly maximumAltitudeAgl: number,
  readonly deleted: boolean,
  readonly canDelete: boolean,
  readonly canCancel: boolean,
  readonly registrationNumber?: string,
  readonly denialReason?: string,
  readonly denialReasonCode?: LaancDenialReasonCode
  readonly denialReasonCodeText?: string
}

/**
 * Information and Status for individual submission to LAANC.
 *
 * @param submissionId       internal id for this submission
 * @param operatorIntentId   internal operator Intent id to create this operation
 * @param operationId        internal operation ID
 * @param referenceCode      LAANC reference code
 * @param timeSubmitted      time submitted to LAANC
 * @param timeUpdated        time submission state last updated
 * @param expiresAt          approximate time submission will expire
 * @param type               submitted for auto approval or further coordination request
 * @param category           submitted for Part 107 or Section 44809
 * @param state              current state of this submission
 * @param uasfmIds           list of IDs of UASFMs this submission touches
 * @param airspaceClasses    classes of airspace this submission touches
 * @param faaFacilityCode    faa identifier of authorizing facility/airport
 * @param authorizingAirport authorizing facility/airport
 * @param userId             internal user ID submitting
 * @param operationName      display name of operation
 * @param poc                submitted contact
 * @param registrationNumber submitted Registration number
 * @param volume             submitted volume
 * @param maximumAltitudeAgl submitted maximum altitude in AGL FT
 * @param denialReason       reason for denial if applicable
 * @param denialReasonCode   reason code received from FAA LAANC API if applicable
 * @param denialReasonCodeText   denialReasonCode as user friendly text for UI
 */
export class LaancSubmissionEntry implements ILaancSubmissionEntry, BaseModel {
  readonly submissionId: string;
  readonly operatorIntentId: string;
  readonly operationId: string;
  readonly referenceCode: string;
  readonly timeSubmitted: Date;
  readonly timeUpdated: DateTime;
  readonly expiresAt: DateTime;
  readonly type: LaancSubmissionType;
  readonly category: LaancSubmissionCategory;
  readonly state: LaancSubmissionState;
  readonly uasfmIds: FacilityMapId[];
  readonly airspaceClasses: AirspaceClassType[];
  readonly faaFacilityCode: string;
  readonly authorizingAirport: Airport;
  readonly userId: string;
  readonly operationName: string;
  readonly poc: LaancPoc;
  readonly volume: EntityVolume4d;
  readonly maximumAltitudeAgl: number;
  readonly deleted: boolean;
  readonly canDelete: boolean;
  readonly canCancel: boolean;
  readonly registrationNumber?: string;
  readonly denialReason?: string;
  readonly denialReasonCode?: LaancDenialReasonCode;
  readonly denialReasonCodeText?: string;

  constructor(values: ILaancSubmissionEntry) {
    this.submissionId = values.submissionId;
    this.operatorIntentId = values.operatorIntentId;
    this.operationId = values.operationId;
    this.referenceCode = values.referenceCode;
    this.timeSubmitted = values.timeSubmitted;
    this.timeUpdated = values.timeUpdated;
    this.expiresAt = values.expiresAt;
    this.type = values.type;
    this.category = values.category;
    this.state = values.state;
    this.uasfmIds = values.uasfmIds;
    this.airspaceClasses = values.airspaceClasses;
    this.faaFacilityCode = values.faaFacilityCode;
    this.authorizingAirport = values.authorizingAirport;
    this.userId = values.userId;
    this.operationName = values.operationName;
    this.poc = values.poc;
    this.volume = values.volume;
    this.maximumAltitudeAgl = values.maximumAltitudeAgl;
    this.deleted = values.deleted;
    this.canDelete = values.canDelete;
    this.canCancel = values.canCancel;
    this.registrationNumber = values.registrationNumber;
    this.denialReason = values.denialReason;
    this.denialReasonCode = values.denialReasonCode;
    this.denialReasonCodeText = values.denialReasonCodeText;
  }
}

export class LaancSubmissionEntryUtil implements BaseModelDeserializer<TLaancSubmissionEntry, LaancSubmissionEntry> {
  deserialize(raw: unknown): Result<LaancSubmissionEntry> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC submission entry');
    if (!isObject(raw)) return fail('Invalid type for LAANC submission entry');

    if (!('submissionId' in raw) || isEmpty(raw.submissionId)) return fail('No LAANC submission entry ID value');
    if (typeof raw.submissionId !== 'string') return fail('Invalid LAANC submission entry ID value');

    if (!('operatorIntentId' in raw) || isEmpty(raw.operatorIntentId)) return fail('No LAANC submission entry operator intent ID value');
    if (typeof raw.operatorIntentId !== 'string') return fail('Invalid LAANC submission entry operator intent ID value');

    if (!('operationId' in raw) || isEmpty(raw.operationId)) return fail('No LAANC submission entry operation ID value');
    if (typeof raw.operationId !== 'string') return fail('Invalid LAANC submission entry operation ID value');

    if (!('referenceCode' in raw) || isEmpty(raw.referenceCode)) return fail('No LAANC submission entry reference code value');
    if (typeof raw.referenceCode !== 'string') return fail('Invalid LAANC submission entry reference code value');

    if (!('timeSubmitted' in raw) || isEmpty(raw.timeSubmitted)) return fail('No LAANC submission entry time submitted value');
    if (typeof raw.timeSubmitted !== 'string') return fail('Invalid LAANC submission entry time submitted value');
    let timeSubmitted = new Date(raw.timeSubmitted);
    if (!timeSubmitted || Number.isNaN(timeSubmitted.valueOf())) return fail('Invalid LAANC submission entry time submitted value');

    if (!('timeUpdated' in raw) || isEmpty(raw.timeUpdated)) return fail('No LAANC submission entry time updated value');
    if (typeof raw.timeUpdated !== 'string') return fail('Invalid LAANC submission entry time updated value');
    let timeUpdated = DateTime.fromISO(raw.timeUpdated);
    if (!timeUpdated.isValid) return fail('Invalid LAANC submission entry time updated value');

    if (!('expiresAt' in raw) || isEmpty(raw.expiresAt)) return fail('No LAANC submission entry expires at value');
    if (typeof raw.expiresAt !== 'string') return fail('Invalid LAANC submission entry expires at value');
    let expiresAt = DateTime.fromISO(raw.expiresAt);
    if (!expiresAt.isValid) return fail('Invalid LAANC submission entry expires at value');

    if (!('type' in raw) || isEmpty(raw.type)) return fail('No LAANC submission entry type value');
    let type = DEFAULT_LAANC_SUBMISSION_TYPE_UTIL.deserialize(raw.type);
    if (type.type === 'error') return fail(type.message);

    if (!('category' in raw) || isEmpty(raw.category)) return fail('No LAANC submission entry category value');
    let category = DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL.deserialize(raw.category);
    if (category.type === 'error') return fail(category.message);

    if (!('state' in raw) || isEmpty(raw.state)) return fail('No LAANC submission entry state value');
    let state = DEFAULT_LAANC_SUBMISSION_STATE_UTIL.deserialize(raw.state);
    if (state.type === 'error') return fail(state.message);

    if (!('uasfmIds' in raw) || isEmpty(raw.uasfmIds)) return fail('No LAANC submission entry UAS FM IDs value');
    if (!Array.isArray(raw.uasfmIds)) return fail('Invalid type for LAANC submission entry UAS FM IDs array');
    const uasFmIds = DEFAULT_FACILITY_MAP_ID_UTIL.deserializeArray(raw.uasfmIds);
    if (uasFmIds.type === 'error') return fail(uasFmIds.message);

    if (!('airspaceClasses' in raw)  || isEmpty(raw.airspaceClasses)) return fail('No LAANC submission entry airspace classes value');
    if (!Array.isArray(raw.airspaceClasses)) return fail('Invalid type for LAANC submission entry airspace classes array');
    if (raw.airspaceClasses.some(cls => DEFAULT_AIRSPACE_CLASS_TYPE_UTIL.deserialize(cls).type === 'error')) return
      fail('Invalid LAANC submission entry airspace classes value');

    if (!('faaFacilityCode' in raw) || isEmpty(raw.faaFacilityCode)) return fail('No LAANC submission entry FAA facility code value');
    if (typeof raw.faaFacilityCode !== 'string') return fail('Invalid LAANC submission entry FAA facility code value');

    if (!('authorizingAirport' in raw) || isEmpty(raw.authorizingAirport)) return fail('No LAANC submission entry authorizing airport value');
    let authorizingAirport = DEFAULT_AIRPORT_UTIL.deserialize(raw.authorizingAirport);
    if (authorizingAirport.type === 'error') return fail(authorizingAirport.message);

    if (!('userId' in raw) || isEmpty(raw.userId)) return fail('No LAANC submission entry user ID value');
    if (typeof raw.userId !== 'string') return fail('Invalid LAANC submission entry user ID value');

    if (!('operationName' in raw) || isEmpty(raw.operationName)) return fail('No LAANC submission entry operation name value');
    if (typeof raw.operationName !== 'string') return fail('Invalid LAANC submission entry operation name value');

    if (!('poc' in raw) || isEmpty(raw.poc)) return fail('No LAANC submission entry POC value');
    let poc = DEFAULT_LAANC_POC_UTIL.deserialize(raw.poc);
    if (poc.type === 'error') return fail(poc.message);

    if (!('volume' in raw) || isEmpty(raw.volume)) return fail('No LAANC submission entry volume value');
    // TODO: Change this to use the deserialize method when the EntityVolume4d model is refactored to implement the BaseModel
    let volume = Parser.parseConstraintVolume(raw.volume as IEntityVolume4d);

    if (!('maximumAltitudeAgl' in raw) || isEmpty(raw.maximumAltitudeAgl)) return fail('No LAANC submission entry maximum altitude AGL value');
    if (typeof raw.maximumAltitudeAgl !== 'number') return fail('Invalid LAANC submission entry maximum altitude AGL value');

    if (!('deleted' in raw) || isEmpty(raw.deleted)) return fail('No LAANC submission entry deleted value');
    if (typeof raw.deleted !== 'boolean') return fail('Invalid LAANC submission entry deleted value');

    if (!('canDelete' in raw) || isEmpty(raw.canDelete)) return fail('No LAANC submission entry canDelete value');
    if (typeof raw.canDelete !== 'boolean') return fail('Invalid LAANC submission entry canDelete value');

    if (!('canCancel' in raw) || isEmpty(raw.canCancel)) return fail('No LAANC submission entry canCancel value');
    if (typeof raw.canCancel !== 'boolean') return fail('Invalid LAANC submission entry canCancel value');

    let registrationNumber: string | undefined = undefined;
    if (('registrationNumber' in raw) && !isEmpty(raw.registrationNumber)) {
      if (typeof raw.registrationNumber !== 'string') return fail('Invalid LAANC submission entry registration number value');
      registrationNumber = raw.registrationNumber;
    }

    let denialReason: string | undefined = undefined;
    if (('denialReason' in raw) && !isEmpty(raw.denialReason)) {
      if (typeof raw.denialReason !== 'string') return fail('Invalid LAANC submission entry denial reason value');
      denialReason = raw.denialReason;
    }

    let denialReasonCode: LaancDenialReasonCode | undefined = undefined;
    if (('denialReasonCode' in raw) && !isEmpty(raw.denialReasonCode)) {
      const denialReasonCodeResult = DEFAULT_LAANC_DENIAL_REASON_CODE_UTIL.deserialize(raw.denialReasonCode);
      if (denialReasonCodeResult.type === 'error') return fail(denialReasonCodeResult.message);
      denialReasonCode = denialReasonCodeResult.value;
    }

    let denialReasonCodeText: string | undefined = undefined;
    if (('denialReasonCodeText' in raw) && !isEmpty(raw.denialReasonCodeText)) {
      if (typeof raw.denialReasonCodeText !== 'string') return fail('Invalid LAANC submission entry denial reason code text value');
      denialReasonCodeText = raw.denialReasonCodeText;
    }

    return some(new LaancSubmissionEntry({
      submissionId: raw.submissionId,
      operatorIntentId: raw.operatorIntentId,
      operationId: raw.operationId,
      referenceCode: raw.referenceCode,
      timeSubmitted,
      timeUpdated,
      expiresAt,
      type: type.value,
      category: category.value,
      state: state.value,
      uasfmIds: uasFmIds.value,
      airspaceClasses: raw.airspaceClasses,
      faaFacilityCode: raw.faaFacilityCode,
      authorizingAirport: authorizingAirport.value,
      userId: raw.userId,
      operationName: raw.operationName,
      poc: poc.value,
      volume,
      maximumAltitudeAgl: raw.maximumAltitudeAgl,
      deleted: raw.deleted,
      canDelete: raw.canDelete,
      canCancel: raw.canCancel,
      registrationNumber: registrationNumber,
      denialReason,
      denialReasonCode,
      denialReasonCodeText
    }));
  }

  deserializeArray(raw: unknown): Result<LaancSubmissionEntry[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for LAANC submission entry array');
    const results: LaancSubmissionEntry[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_LAANC_SUBMISSION_ENTRY_UTIL = new LaancSubmissionEntryUtil();

export interface TLaancSubmissionsSearchResponse {
  readonly authorizations: TLaancSubmissionEntry[];
}

/**
 * LAANC Submission Search Response
 * This model is used for the deserialized data that is returned by the LAANC submission search endpoint
 */
export class LaancSubmissionsSearchResponse implements BaseModel {
  readonly authorizations: LaancSubmissionEntry[];

  constructor(authorizations: LaancSubmissionEntry[]) {
    this.authorizations = authorizations;
  }
}

/**
 * LAANC Submission Search Response Utility
 * This model is used to deserialize the response from the LAANC submission search endpoint
 */
export class LaancSubmissionsSearchResponseUtil implements BaseModelDeserializer<TLaancSubmissionsSearchResponse, LaancSubmissionsSearchResponse> {
  deserialize(raw: unknown): Result<LaancSubmissionsSearchResponse> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC submissions search response');
    if (!isObject(raw)) return fail('Invalid type for LAANC submissions search response');

    if (!('authorizations' in raw)) return fail('No LAANC submissions search response submissions value');
    const authorizations = DEFAULT_LAANC_SUBMISSION_ENTRY_UTIL.deserializeArray(raw.authorizations);
    if (authorizations.type === 'error') return fail(authorizations.message);

    return some(new LaancSubmissionsSearchResponse(authorizations.value));
  }
}

export const DEFAULT_LAANC_SUBMISSIONS_SEARCH_RESPONSE_UTIL = new LaancSubmissionsSearchResponseUtil();
