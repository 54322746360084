import {BaseEnumDeserializer} from "../../../common";
import {fail, Result, some} from '../../../../utils';

export enum DataSyncFailureReason {
  OTHER = 'OTHER',
  FAILED_META_CHECK = 'FAILED_META_CHECK',
  FAILED_DOWNLOADING = 'FAILED_DOWNLOADING',
  FAILED_PARSING_DOWNLOAD = 'FAILED_PARSING_DOWNLOAD',
  FAILED_POPULATING_DB = 'FAILED_POPULATING_DB',
}

export class DataSyncFailureReasonUtil implements BaseEnumDeserializer<DataSyncFailureReason, DataSyncFailureReason> {
  humanized: {[key in DataSyncFailureReason]: string} = {
    [DataSyncFailureReason.OTHER]: 'Other',
    [DataSyncFailureReason.FAILED_META_CHECK]: 'Failed meta check',
    [DataSyncFailureReason.FAILED_DOWNLOADING]: 'Failed downloading',
    [DataSyncFailureReason.FAILED_PARSING_DOWNLOAD]: 'Failed parsing download',
    [DataSyncFailureReason.FAILED_POPULATING_DB]: 'Failed populating database'
  };

  deserialize(raw: unknown): Result<DataSyncFailureReason> {
    if (typeof raw !== 'string') return fail('Invalid type for LAANC data sync failure reason');

    switch(raw.toUpperCase()) {
      case 'OTHER':
        return some(DataSyncFailureReason.OTHER);
      case 'FAILED_META_CHECK':
        return some(DataSyncFailureReason.FAILED_META_CHECK);
      case 'FAILED_DOWNLOADING':
        return some(DataSyncFailureReason.FAILED_DOWNLOADING);
      case 'FAILED_PARSING_DOWNLOAD':
        return some(DataSyncFailureReason.FAILED_PARSING_DOWNLOAD);
      case 'FAILED_POPULATING_DB':
        return some(DataSyncFailureReason.FAILED_POPULATING_DB);
      default:
        return fail(`Invalid LAANC data sync failure reason: ${raw}`);    }
  }
}

export const DEFAULT_DATA_SYNC_FAILURE_REASON_UTIL = new DataSyncFailureReasonUtil();
