import {DateTime} from 'luxon';
import {
  AirspaceDataSyncStatus,
  AirspaceDataType,
  DataSyncFailureReason,
  DEFAULT_AIRSPACE_DATA_SYNC_STATUS_UTIL,
  DEFAULT_AIRSPACE_DATA_TYPE_UTIL,
  DEFAULT_DATA_SYNC_FAILURE_REASON_UTIL
} from './enums';
import {BaseModel, BaseModelDeserializer} from '../../common';
import {fail, isEmpty, isObject, Result, some} from '../../../utils';

export interface TAirspaceDataSyncEntry {
  readonly datasyncId: string;
  readonly timeInitiated: string;
  readonly timeStatusUpdated: string;
  readonly dataType: AirspaceDataType;
  readonly displayName: string;
  readonly syncStatus: AirspaceDataSyncStatus;
  readonly available: boolean;
  readonly downloadUrl: string;
  readonly metaUrl?: string;
  readonly metaMoreRecent?: boolean;
  readonly sourceUpdateTime?: string;
  readonly timeDownloaded?: string;
  readonly timePopulated?: string;
  readonly timeCompleted?: string
  readonly failureReason?: DataSyncFailureReason;
  readonly error?: string;
  readonly active?: boolean;
}

interface IAirspaceDataSyncEntry {
  readonly datasyncId: string;
  readonly timeInitiated: DateTime;
  readonly timeStatusUpdated: DateTime;
  readonly dataType: AirspaceDataType;
  readonly displayName: string;
  readonly syncStatus: AirspaceDataSyncStatus;
  readonly available: boolean;
  readonly downloadUrl: string;
  readonly metaUrl?: string;
  readonly metaMoreRecent?: boolean;
  readonly sourceUpdateTime?: DateTime;
  readonly timeDownloaded?: DateTime;
  readonly timePopulated?: DateTime;
  readonly timeCompleted?: DateTime;
  readonly failureReason?: DataSyncFailureReason;
  readonly error?: string;
  readonly active?: boolean;
}

export class AirspaceDataSyncEntry implements IAirspaceDataSyncEntry, BaseModel {
  readonly datasyncId: string;
  readonly timeInitiated: DateTime;
  readonly timeStatusUpdated: DateTime;
  readonly dataType: AirspaceDataType;
  readonly displayName: string;
  readonly syncStatus: AirspaceDataSyncStatus;
  readonly available: boolean;
  readonly downloadUrl: string;
  readonly metaUrl?: string;
  readonly metaMoreRecent?: boolean;
  readonly sourceUpdateTime?: DateTime;
  readonly timeDownloaded?: DateTime;
  readonly timePopulated?: DateTime;
  readonly timeCompleted?: DateTime;
  readonly failureReason?: DataSyncFailureReason;
  readonly error?: string;
  readonly active?: boolean;

  constructor(values: IAirspaceDataSyncEntry) {
    this.datasyncId = values.datasyncId;
    this.timeInitiated = values.timeInitiated;
    this.timeStatusUpdated = values.timeStatusUpdated;
    this.dataType = values.dataType;
    this.displayName = values.displayName;
    this.syncStatus = values.syncStatus;
    this.available = values.available;
    this.downloadUrl = values.downloadUrl;
    this.metaUrl = values.metaUrl;
    this.metaMoreRecent = values.metaMoreRecent;
    this.sourceUpdateTime = values.sourceUpdateTime;
    this.timeDownloaded = values.timeDownloaded;
    this.timePopulated = values.timePopulated;
    this.timeCompleted = values.timeCompleted;
    this.failureReason = values.failureReason;
    this.error = values.error;
    this.active = values.active;
  }
}

export class AirspaceDataSyncEntryUtil implements BaseModelDeserializer<TAirspaceDataSyncEntry, AirspaceDataSyncEntry> {
  deserialize(raw: unknown): Result<AirspaceDataSyncEntry> {
    if (isEmpty(raw)) return fail('No data supplied for airspace data sync entry');
    if (!isObject(raw)) return fail('Invalid data supplied for airspace data sync entry');

    if (!('datasyncId' in raw) || isEmpty(raw.datasyncId)) return fail('No airspace data sync ID value');
    if (typeof raw.datasyncId !== 'string') return fail('Invalid airspace data sync ID value');

    if (!('timeInitiated' in raw) || isEmpty(raw.timeInitiated)) return fail('No airspace data sync time initiated value');
    if (typeof raw.timeInitiated !== 'string') return fail('Invalid airspace data sync time initiated value');
    let timeInitiated = DateTime.fromISO(raw.timeInitiated);
    if (!timeInitiated.isValid) return fail('Invalid airspace data sync time initiated value');

    if (!('timeStatusUpdated' in raw) || isEmpty(raw.timeStatusUpdated)) return fail('No airspace data sync time status updated value');
    if (typeof raw.timeStatusUpdated !== 'string') return fail('Invalid airspace data sync time status updated value');
    let timeStatusUpdated = DateTime.fromISO(raw.timeStatusUpdated);
    if (!timeStatusUpdated.isValid) return fail('Invalid airspace data sync time status updated value');

    if (!('dataType' in raw) || isEmpty(raw.dataType)) return fail('No airspace data sync data type value');
    const dataType = DEFAULT_AIRSPACE_DATA_TYPE_UTIL.deserialize(raw.dataType);
    if (dataType.type === 'error') return fail(dataType.message);

    if (!('displayName' in raw) || isEmpty(raw.displayName)) return fail('No airspace data sync display name value');
    if (typeof raw.displayName !== 'string') return fail('Invalid airspace data sync display name value');

    if (!('syncStatus' in raw) || isEmpty(raw.syncStatus)) return fail('No airspace data sync status value');
    const syncStatus = DEFAULT_AIRSPACE_DATA_SYNC_STATUS_UTIL.deserialize(raw.syncStatus);
    if (syncStatus.type === 'error') return fail(syncStatus.message);

    if (!('available' in raw) || isEmpty(raw.available)) return fail('No airspace data sync available value');
    if (typeof raw.available !== 'boolean') return fail('Invalid airspace data sync available value');

    if (!('downloadUrl' in raw) || isEmpty(raw.downloadUrl)) return fail('No airspace data sync download URL value');
    if (typeof raw.downloadUrl !== 'string') return fail('Invalid airspace data sync download URL value');

    let metaUrl: string | undefined = undefined;
    if (('metaUrl' in raw) && !isEmpty(raw.metaUrl)) {
      if (typeof raw.metaUrl !== 'string') return fail('Invalid airspace data sync meta URL value');
      metaUrl = raw.metaUrl;
    }

    let metaMoreRecent: boolean | undefined = undefined;
    if (('metaMoreRecent' in raw) && !isEmpty(raw.metaMoreRecent)) {
      if (typeof raw.metaMoreRecent !== 'boolean') return fail('Invalid airspace data sync meta more recent value');
      metaMoreRecent = raw.metaMoreRecent;
    }

    let sourceUpdateTime: DateTime | undefined = undefined;
    if (('sourceUpdateTime' in raw) && !isEmpty(raw.sourceUpdateTime)) {
      if (typeof raw.sourceUpdateTime !== 'string') return fail('Invalid airspace data sync source update time value');
      sourceUpdateTime = DateTime.fromISO(raw.sourceUpdateTime);
      if (!sourceUpdateTime.isValid) return fail('Invalid airspace data sync source update time value');
    }

    let timeDownloaded: DateTime | undefined = undefined;
    if (('timeDownloaded' in raw) && !isEmpty(raw.timeDownloaded)) {
      if (typeof raw.timeDownloaded !== 'string') return fail('Invalid airspace data sync time downloaded value');
      timeDownloaded = DateTime.fromISO(raw.timeDownloaded);
      if (!timeDownloaded.isValid) return fail('Invalid airspace data sync time downloaded value');
    }

    let timePopulated: DateTime | undefined = undefined;
    if (('timePopulated' in raw) && !isEmpty(raw.timePopulated)) {
      if (typeof raw.timePopulated !== 'string') return fail('Invalid airspace data sync time populated value');
      timePopulated = DateTime.fromISO(raw.timePopulated);
      if (!timePopulated.isValid) return fail('Invalid airspace data sync time populated value');
    }

    let timeCompleted: DateTime | undefined = undefined;
    if (('timeCompleted' in raw) && !isEmpty(raw.timeCompleted)) {
      if (typeof raw.timeCompleted !== 'string') return fail('Invalid airspace data sync time completed value');
      timeCompleted = DateTime.fromISO(raw.timeCompleted);
      if (!timeCompleted.isValid) return fail('Invalid airspace data sync time completed value');
    }

    let failureReason: DataSyncFailureReason | undefined = undefined;
    if (('failureReason' in raw) && !isEmpty(raw.failureReason)) {
      const reason = DEFAULT_DATA_SYNC_FAILURE_REASON_UTIL.deserialize(raw.failureReason);
      if (reason.type === 'error') return fail(reason.message);
      failureReason = reason.value;
    }

    let error: string | undefined = undefined;
    if (('error' in raw) && !isEmpty(raw.error)) {
      if (typeof raw.error !== 'string') return fail('Invalid airspace data sync error value');
      error = raw.error;
    }

    let active: boolean | undefined = undefined;
    if (('active' in raw) && !isEmpty(raw.active)) {
      if (typeof raw.active !== 'boolean') return fail('Invalid airspace data sync active value');
      active = raw.active;
    }

    return some(new AirspaceDataSyncEntry({
      datasyncId: raw.datasyncId,
      timeInitiated,
      timeStatusUpdated,
      dataType: dataType.value,
      displayName: raw.displayName,
      syncStatus: syncStatus.value,
      available: raw.available,
      downloadUrl: raw.downloadUrl,
      metaUrl,
      metaMoreRecent,
      sourceUpdateTime,
      timeDownloaded,
      timePopulated,
      timeCompleted,
      failureReason: failureReason,
      error,
      active
    }));
  }

  deserializeArray(raw: unknown): Result<AirspaceDataSyncEntry[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for airspace data sync entry array');
    const results: AirspaceDataSyncEntry[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_AIRSPACE_DATA_SYNC_ENTRY_UTIL = new AirspaceDataSyncEntryUtil();

export interface TDataSyncListResponse {
  readonly items: TAirspaceDataSyncEntry[];
}

/**
 * Data Sync List Response
 * This model is used to represent the response returned by the LAANC data sync endpoint
 */
export class DataSyncListResponse {
  readonly items: AirspaceDataSyncEntry[];

  constructor(items: AirspaceDataSyncEntry[]) {
    this.items = items;
  }
}

/**
 * Data Sync List Response Util
 * This model is used to deserialize the response returned by the LAANC data sync endpoint
 */
export class DataSyncListResponseUtil implements BaseModelDeserializer<TDataSyncListResponse, DataSyncListResponse> {
  deserialize(raw: unknown): Result<DataSyncListResponse> {
    if (isEmpty(raw)) return fail('No data supplied for data sync list response');
    if (!isObject(raw)) return fail('Invalid type supplied for data sync list response');

    if (!('items' in raw) || isEmpty(raw.items)) return fail('No data sync list response items value');
    const items = DEFAULT_AIRSPACE_DATA_SYNC_ENTRY_UTIL.deserializeArray(raw.items);
    if (items.type === 'error') return fail(items.message);

    return some(new DataSyncListResponse(items.value));
  }
}

export const DEFAULT_DATA_SYNC_LIST_RESPONSE_UTIL = new DataSyncListResponseUtil();

export interface TLaancDataSetsResponse {
  readonly datasets: {[key in AirspaceDataType]: TAirspaceDataSyncEntry[]};
}

export type LaancDataSets = {[key in AirspaceDataType]: AirspaceDataSyncEntry[]};

/**
 * LAANC Datasets Response
 * This model is used to represent the available datasets that are returned by the LAANC data sync available endpoint
 */
export class LaancDataSetsResponse implements BaseModel {
  readonly datasets: LaancDataSets;

  constructor(datasets: LaancDataSets) {
    this.datasets = datasets;
  }
}

/**
 * LAANC Datasets Response Util
 * This model is used to deserialize the response returned by the LAANC data sync available endpoint
 */
export class LaancDataSetsResponseUtil implements BaseModelDeserializer<TLaancDataSetsResponse, LaancDataSetsResponse> {
  deserialize(raw: unknown): Result<LaancDataSetsResponse> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC datasets response');
    if (!isObject(raw)) return fail('Invalid data supplied for LAANC datasets response');

    if (!('datasets' in raw) || isEmpty(raw.datasets)) return fail('No LAANC datasets response value');
    if (!isObject(raw.datasets)) return fail('Invalid LAANC datasets response value');

    const datasets = {} as LaancDataSets;
    let error = '';
    Object.entries(raw.datasets).some(([key, value]) => {
      const dataType = DEFAULT_AIRSPACE_DATA_TYPE_UTIL.deserialize(key);
      if (dataType.type === 'error') {
        error = dataType.message;
        return true;
      }

      const data = DEFAULT_AIRSPACE_DATA_SYNC_ENTRY_UTIL.deserializeArray(value);
      if (data.type === 'error') {
        error = data.message;
        return true;
      }

      datasets[dataType.value] = data.value;
      return false;
    });

    if (error) return fail(error);

    return some(new LaancDataSetsResponse(datasets));
  }
}

export const DEFAULT_AVAILABLE_DATA_SETS_UTIL = new LaancDataSetsResponseUtil();


