import {
  AirspaceClassType,
  BaseModel,
  BaseModelDeserializer,
  DEFAULT_AIRSPACE_CLASS_TYPE_UTIL,
  DEFAULT_FACILITY_MAP_ID_UTIL,
  DEFAULT_LAANC_SUBMISSION_TYPE_UTIL,
  FacilityMapId,
  fail,
  isEmpty,
  isObject,
  LaancSubmissionType,
  Result,
  some,
  TFacilityMapId
} from '@ax-uss-ui/common';
import {EntityVolume4d} from '../gen/utm';
import {IEntityVolume4d} from '../gen/transport/utm';
import {Parser} from '../utm/parser/OperationParser';

interface ILaancVolumePreview {
  readonly type: LaancSubmissionType;
  readonly volume: EntityVolume4d;
  readonly maximumAltitudeAgl: number;
  readonly faaFacilityCode: string;
  readonly uasfmIds: FacilityMapId[];
  readonly airspaceClasses: AirspaceClassType[];
}

export interface TLaancVolumePreview {
  readonly type: LaancSubmissionType;
  readonly volume: IEntityVolume4d;
  readonly maximumAltitudeAgl: number;
  readonly faaFacilityCode: string;
  readonly uasfmIds: TFacilityMapId[];
  readonly airspaceClasses: AirspaceClassType[];
}

export class LaancVolumePreview implements ILaancVolumePreview, BaseModel {
  readonly type: LaancSubmissionType;
  readonly volume: EntityVolume4d;
  readonly maximumAltitudeAgl: number;
  readonly faaFacilityCode: string;
  readonly uasfmIds: FacilityMapId[];
  readonly airspaceClasses: AirspaceClassType[];

  constructor(values: ILaancVolumePreview) {
    this.type = values.type;
    this.volume = values.volume;
    this.maximumAltitudeAgl = values.maximumAltitudeAgl;
    this.faaFacilityCode = values.faaFacilityCode;
    this.uasfmIds = values.uasfmIds;
    this.airspaceClasses = values.airspaceClasses
  }
}

export class LaancVolumePreviewUtil implements BaseModelDeserializer<TLaancVolumePreview, LaancVolumePreview> {
  deserialize(raw: unknown): Result<LaancVolumePreview> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC volume preview');
    if (!isObject(raw)) return fail('Invalid type for LAANC volume preview');

    if (!('type' in raw) || isEmpty(raw.type)) return fail('No LAANC volume preview type value');
    let type = DEFAULT_LAANC_SUBMISSION_TYPE_UTIL.deserialize(raw.type);
    if (type.type === 'error') return fail(type.message);

    if (!('volume' in raw) || isEmpty(raw.volume)) return fail('No LAANC volume preview volume value');
    // TODO: Change this to use the deserialize method when the EntityVolume4d model is refactored to implement the BaseModel
    let volume = Parser.parseConstraintVolume(raw.volume as IEntityVolume4d);

    if (!('maximumAltitudeAgl' in raw) || isEmpty(raw.maximumAltitudeAgl)) return fail('No LAANC volume preview maximum altitude AGL value');
    if (typeof raw.maximumAltitudeAgl !== 'number') return fail('Invalid LAANC volume preview maximum altitude AGL value');

    if (!('faaFacilityCode' in raw) || isEmpty(raw.faaFacilityCode)) return fail('No LAANC volume preview FAA facility code value');
    if (typeof raw.faaFacilityCode !== 'string') return fail('Invalid LAANC volume preview FAA facility code value');

    if (!('uasfmIds' in raw) || isEmpty(raw.uasfmIds)) return fail('No LAANC volume preview UASFM IDs value');
    const uasfmIds = DEFAULT_FACILITY_MAP_ID_UTIL.deserializeArray(raw.uasfmIds);
    if (uasfmIds.type === 'error') return fail(uasfmIds.message);

    if (!('airspaceClasses' in raw) || isEmpty(raw.airspaceClasses)) return fail('No LAANC volume preview airspace classes value');
    if (!Array.isArray(raw.airspaceClasses)) return fail('Invalid type for LAANC volume preview airspace classes array');
    if (raw.airspaceClasses.some(cls => DEFAULT_AIRSPACE_CLASS_TYPE_UTIL.deserialize(cls).type === 'error')) return
      fail('Invalid LAANC volume preview airspace class value');

    return some(new LaancVolumePreview({
      type: type.value,
      volume: volume,
      maximumAltitudeAgl: raw.maximumAltitudeAgl,
      faaFacilityCode: raw.faaFacilityCode,
      uasfmIds: uasfmIds.value,
      airspaceClasses: raw.airspaceClasses
    }));
  }

  deserializeArray(raw: unknown): Result<LaancVolumePreview[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for LAANC volume preview array');
    const results: LaancVolumePreview[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_LAANC_VOLUME_PREVIEW_UTIL = new LaancVolumePreviewUtil();
