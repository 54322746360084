import {
  Airport,
  BaseModel,
  BaseModelDeserializer,
  ClassAirspace,
  CZMLPacket,
  DEFAULT_AIRPORT_UTIL,
  DEFAULT_CLASS_AIRSPACE_UTIL,
  DEFAULT_LAANC_FR_RULES_UTIL,
  DEFAULT_LAANC_REQUIREMENT_UTIL,
  DEFAULT_NSUFR_FULL_TIME_UTIL,
  DEFAULT_NSUFR_PART_TIME_UTIL,
  DEFAULT_SPECIAL_USE_AIRSPACE_UTIL,
  DEFAULT_STADIUM_UTIL,
  DEFAULT_TFR_DATA_UTIL,
  fail,
  isEmpty,
  isObject,
  LaancFrRules,
  LaancRequirement,
  NsufrFullTime,
  NsufrPartTime,
  Result,
  some,
  SpecialUseAirspace,
  Stadium,
  TAirport,
  TClassAirspace,
  TfrData,
  TNsufrFullTime,
  TNsufrPartTime,
  TSpecialUseAirspace,
  TStadium,
  TTfrData
} from '@ax-uss-ui/common';
import {DEFAULT_LAANC_VOLUME_PREVIEW_UTIL, LaancVolumePreview, TLaancVolumePreview} from './LaancVolumePreview';

export interface ILaancCheckResponse {
  readonly requirement: LaancRequirement;
  readonly advisories?: LaancFrRules[],
  readonly blocking?: LaancFrRules[],
  readonly uasfms?: CZMLPacket[];
  readonly classAirspaces?: ClassAirspace[],
  readonly airports?: Airport[],
  readonly specialUseAirspaces?: SpecialUseAirspace[],
  readonly stadiums?: Stadium[],
  readonly nsufrsFullTime?: NsufrFullTime[],
  readonly nsufrsPartTime?: NsufrPartTime[],
  readonly tfrs?: TfrData[];
  readonly volumes?: LaancVolumePreview[]
}

export interface TLaancCheckResponse {
  readonly requirement: LaancRequirement;
  readonly advisories?: LaancFrRules[],
  readonly blocking?: LaancFrRules[],
  readonly uasfms?: CZMLPacket[];
  readonly classAirspaces?: TClassAirspace[],
  readonly airports?: TAirport[],
  readonly specialUseAirspaces?: TSpecialUseAirspace[],
  readonly stadiums?: TStadium[],
  readonly nsfrsFullTime?: TNsufrFullTime[],
  readonly nsufrPartTime?: TNsufrPartTime[],
  readonly tfrs?: TTfrData[],
  readonly volumes?: TLaancVolumePreview[];
}

/**
 *
 * @param requirement
 * @param advisories
 * @param blocking
 * @param uasfms UASFMs in CZML Format
 * @param classAirspaces
 * @param airports
 * @param specialUseAirspaces
 * @param stadiums
 * @param nsfrsFullTime
 * @param nsufrPartTime
 * @param tfrs
 * @param volumes preview of volumes to submit to laanc if LaancRequirement is REQUIRE_AUTO_APPROVAL or REQUIRE_FURTHER_COORDINATION
 */
export class LaancCheckResponse implements ILaancCheckResponse, BaseModel {
  readonly requirement: LaancRequirement;
  readonly advisories?: LaancFrRules[];
  readonly blocking?: LaancFrRules[];
  readonly uasfms?: CZMLPacket[];
  readonly classAirspaces?: ClassAirspace[];
  readonly airports?: Airport[];
  readonly specialUseAirspaces?: SpecialUseAirspace[];
  readonly stadiums?: Stadium[];
  readonly nsufrsFullTime?: NsufrFullTime[];
  readonly nsufrsPartTime?: NsufrPartTime[];
  readonly tfrs?: TfrData[];
  readonly volumes?: LaancVolumePreview[];

  constructor(values: ILaancCheckResponse) {
    this.requirement = values.requirement;
    this.advisories = values.advisories || [];
    this.blocking = values.blocking || [];
    this.uasfms = values.uasfms || [];
    this.classAirspaces = values.classAirspaces || [];
    this.airports = values.airports || [];
    this.specialUseAirspaces = values.specialUseAirspaces || [];
    this.stadiums = values.stadiums || [];
    this.nsufrsFullTime = values.nsufrsFullTime || [];
    this.nsufrsPartTime = values.nsufrsPartTime || [];
    this.tfrs = values.tfrs || [];
    this.volumes = values.volumes || [];
  }
}

export class LaancCheckResponseUtil implements BaseModelDeserializer<TLaancCheckResponse, LaancCheckResponse> {
  deserialize(raw: unknown): Result<LaancCheckResponse> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC Check Response');
    if (!isObject(raw)) return fail('Invalid data supplied for LAANC Check Response');

    if (!('requirement' in raw) || isEmpty(raw.requirement)) return fail('No LAANC Check Response requirement value');
    let requirement = DEFAULT_LAANC_REQUIREMENT_UTIL.deserialize(raw.requirement);
    if (requirement.type === 'error') return fail(requirement.message);

    let advisories: LaancFrRules[] = [];
    if ('advisories' in raw) {
      let advisoriesResult = DEFAULT_LAANC_FR_RULES_UTIL.deserializeArray(raw.advisories);
      if (advisoriesResult.type === 'error') return fail(advisoriesResult.message);
      advisories = advisoriesResult.value;
    }

    let blocking: LaancFrRules[] = [];
    if ('blocking' in raw) {
      let blockingResult = DEFAULT_LAANC_FR_RULES_UTIL.deserializeArray(raw.blocking);
      if (blockingResult.type === 'error') return fail(blockingResult.message);
      blocking = blockingResult.value;
    }

    let uasfms: any = undefined;
    if ('uasfms' in raw) {
      uasfms = raw.uasfms;
    }

    let classAirspaces: ClassAirspace[] = [];
    if ('classAirspaces' in raw) {
      let classAirspacesResult = DEFAULT_CLASS_AIRSPACE_UTIL.deserializeArray(raw.classAirspaces);
      if (classAirspacesResult.type === 'error') return fail(classAirspacesResult.message);
      classAirspaces = classAirspacesResult.value;
    }

    let airports: Airport[] = [];
    if ('airports' in raw) {
      let airportsResult = DEFAULT_AIRPORT_UTIL.deserializeArray(raw.airports);
      if (airportsResult.type === 'error') return fail(airportsResult.message);
      airports = airportsResult.value;
    }

    let specialUseAirspaces: SpecialUseAirspace[] = [];
    if ('specialUseAirspaces' in raw) {
      let specialUseAirspacesResult = DEFAULT_SPECIAL_USE_AIRSPACE_UTIL.deserializeArray(raw.specialUseAirspaces);
      if (specialUseAirspacesResult.type === 'error') return fail(specialUseAirspacesResult.message);
      specialUseAirspaces = specialUseAirspacesResult.value;
    }

    let stadiums: Stadium[] = [];
    if ('stadiums' in raw) {
      let stadiumsResult = DEFAULT_STADIUM_UTIL.deserializeArray(raw.stadiums);
      if (stadiumsResult.type === 'error') return fail(stadiumsResult.message);
      stadiums = stadiumsResult.value;
    }

    let nsufrsFullTime: NsufrFullTime[] = [];
    if ('nsfrsFullTime' in raw) {
      let nsfrsResult = DEFAULT_NSUFR_FULL_TIME_UTIL.deserializeArray(raw.nsfrsFullTime);
      if (nsfrsResult.type === 'error') return fail(nsfrsResult.message);
      nsufrsFullTime = nsfrsResult.value;
    }

    let nsufrsPartTime: NsufrPartTime[] = [];
    if ('nsufrPartTime' in raw) {
      let nsufrPartTimeResult = DEFAULT_NSUFR_PART_TIME_UTIL.deserializeArray(raw.nsufrPartTime);
      if (nsufrPartTimeResult.type === 'error') return fail(nsufrPartTimeResult.message);
      nsufrsPartTime = nsufrPartTimeResult.value;
    }

    let tfrs: TfrData[] = [];
    if ('tfrs' in raw) {
      let tfrsResult = DEFAULT_TFR_DATA_UTIL.deserializeArray(raw.tfrs);
      if (tfrsResult.type === 'error') return fail(tfrsResult.message);
      tfrs = tfrsResult.value;
    }

    let volumes: LaancVolumePreview[] = [];
    if ('volumes' in raw) {
     let volumesResult = DEFAULT_LAANC_VOLUME_PREVIEW_UTIL.deserializeArray(raw.volumes);
      if (volumesResult.type === 'error') return fail(volumesResult.message);
      volumes = volumesResult.value;
    }

    return some(new LaancCheckResponse({
      requirement: requirement.value,
      advisories,
      blocking,
      uasfms,
      classAirspaces,
      airports,
      specialUseAirspaces,
      stadiums,
      nsufrsFullTime,
      nsufrsPartTime,
      tfrs,
      volumes
    }));
  }
}

export const DEFAULT_LAANC_CHECK_RESPONSE_UTIL = new LaancCheckResponseUtil();
