import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {BaseModel, BaseModelDeserializer} from '../common';

export interface ILaancAcknowledgement {
  type: 'Rescinded' | 'InvalidCancel';
  referenceCode: string;
}

interface TLaancOperatorAcknowledgementResponse {
  readonly message: string;
}

export class LaancOperatorAcknowledgementResponse implements BaseModel {
  readonly message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export class LaancOperatorAcknowledgementResponseUtil implements BaseModelDeserializer<TLaancOperatorAcknowledgementResponse, LaancOperatorAcknowledgementResponse> {
  deserialize(raw: unknown): Result<LaancOperatorAcknowledgementResponse> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC Operator Acknowledgement Response');
    if (!isObject(raw)) return fail('Invalid LAANC Operator Acknowledgement Response message value');

    if (!('message' in raw) || isEmpty(raw.message)) return fail('No LAANC Operator Acknowledgement Response message value');
    if (typeof raw.message !== 'string') return fail('Invalid LAANC Operator Acknowledgement Response message value');

    return some(new LaancOperatorAcknowledgementResponse(raw.message));
  }
}

export const DEFAULT_LAANC_OPERATOR_ACKNOWLEDGEMENT_RESPONSE_UTIL = new LaancOperatorAcknowledgementResponseUtil();
