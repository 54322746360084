import {BaseModel, BaseModelDeserializer} from '../common';
import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {DEFAULT_SUA_TYPE_CODE_UTIL, SuaTypeCode} from './enums';

export interface ISpecialUseAirspace {
  readonly restricted?: boolean;
  readonly name?: string;
  readonly typeCode?: SuaTypeCode;
  readonly class?: string;
  readonly city?: string;
  readonly state?: string;
  readonly country?: string;
  readonly timesOfUse?: string;
}

export interface TSpecialUseAirspace {
  readonly restricted?: boolean;
  readonly NAME?: string;
  readonly TYPE_CODE?: string;
  readonly CLASS?: string;
  readonly CITY?: string;
  readonly STATE?: string;
  readonly COUNTRY?: string;
  readonly TIMESOFUSE?: string;
}

export class SpecialUseAirspace implements ISpecialUseAirspace, BaseModel {
  readonly restricted?: boolean;
  readonly name?: string;
  readonly typeCode?: SuaTypeCode;
  readonly class?: string;
  readonly city?: string;
  readonly state?: string;
  readonly country?: string;
  readonly timesOfUse?: string;

  constructor(values: ISpecialUseAirspace) {
    this.restricted = values.restricted;
    this.name = values.name;
    this.typeCode = values.typeCode
    this.class = values.class;
    this.city = values.city;
    this.state = values.state;
    this.country = values.country;
    this.timesOfUse = values.timesOfUse;
  }
}

export class SpecialUseAirspaceUtil implements BaseModelDeserializer<TSpecialUseAirspace, SpecialUseAirspace>{
  deserialize(raw: unknown): Result<SpecialUseAirspace> {
    if (isEmpty(raw)) return fail('No data supplied for special use airspace');
    if (!isObject(raw)) return fail('Invalid data supplied for special use airspace');

    let restricted: boolean | undefined = undefined;
    if (('restricted' in raw) && !isEmpty(raw.restricted)) {
      if (typeof raw.restricted !== 'boolean') return fail('Invalid restricted value');
      restricted = raw.restricted;
    }

    let name: string | undefined = undefined;
    if (('NAME' in raw) && !isEmpty(raw.NAME)) {
      if (typeof raw.NAME !== 'string') return fail('Invalid special use airspace name value');
      name = raw.NAME;
    }

    let typeCode: SuaTypeCode | undefined = undefined;
    if (('TYPE_CODE' in raw) && !isEmpty(raw.TYPE_CODE)) {
      const typeCodeResult = DEFAULT_SUA_TYPE_CODE_UTIL.deserialize(raw.TYPE_CODE);
      if (typeCodeResult.type === 'error') return fail(typeCodeResult.message);
      typeCode = typeCodeResult.value;
    }

    let classSUA: string | undefined = undefined;
    if (('CLASS' in raw) && !isEmpty(raw.CLASS)) {
      if (typeof raw.CLASS !== 'string') return fail('Invalid class value');
      classSUA = raw.CLASS;
    }

    let city: string | undefined = undefined;
    if (('CITY' in raw) && !isEmpty(raw.CITY)) {
      if (typeof raw.CITY !== 'string') return fail('Invalid city value');
      city = raw.CITY;
    }

    let state: string | undefined = undefined;
    if (('STATE' in raw)  && !isEmpty(raw.STATE)) {
      if (typeof raw.STATE !== 'string') return fail('Invalid state value');
      state = raw.STATE;
    }

    let country: string | undefined = undefined;
    if (('COUNTRY' in raw)  && !isEmpty(raw.COUNTRY)) {
      if (typeof raw.COUNTRY !== 'string') return fail('Invalid country value');
      country = raw.COUNTRY;
    }

    let timesOfUse: string | undefined = undefined;
    if (('TIMESOFUSE' in raw) && !isEmpty(raw.TIMESOFUSE)) {
      if(typeof raw.TIMESOFUSE !== 'string') return fail('Invalid times of use value');
      timesOfUse = raw.TIMESOFUSE;
    }

    return some(new SpecialUseAirspace({
      restricted,
      name,
      typeCode,
      class: classSUA,
      city,
      state,
      country,
      timesOfUse
    }));
  }

  deserializeArray(raw: unknown): Result<SpecialUseAirspace[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for special use airspace array');
    const results: SpecialUseAirspace[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_SPECIAL_USE_AIRSPACE_UTIL = new SpecialUseAirspaceUtil();
