import {BaseModel, BaseModelDeserializer} from '../common';
import {fail, isEmpty, isObject, Result, some} from '../../utils';

export interface INsufrFullTime {
  readonly proponent?: string;
  readonly branch?: string;
  readonly base?: string;
  readonly facility?: string;
  readonly airspace?: string;
  readonly faaId?: string;
  readonly poc?: string;
  readonly reason?: string;
  readonly county?: string;
  readonly state?: string;
}

export interface TNsufrFullTime {
  readonly PROPONENT?: string;
  readonly BRANCH?: string;
  readonly BASE?: string;
  readonly FACILITY?: string;
  readonly AIRSPACE?: string;
  readonly FAA_ID?: string;
  readonly POC?: string;
  readonly REASON?: string;
  readonly COUNTY?: string;
  readonly STATE?: string;
}

/**
 * @param OBJECTID  ID Internal Database Field
 * @param PROPONENT Restriction Requestor
 * @param BRANCH    Branch
 * @param BASE      Base
 * @param FACILITY  Facility
 * @param AIRSPACE  Class of Airspace
 * @param FAA_ID    FAA ID
 * @param POC       Point of Contact
 * @param FLOOR     Floor of UAS Flight Restriction
 * @param CEILING   Ceiling of UAS Flight Restriction
 * @param REASON    Reason for the Restriction
 * @param COUNTY    County
 * @param STATE     State
 */
export class NsufrFullTime implements INsufrFullTime, BaseModel {
  readonly proponent?: string;
  readonly branch?: string;
  readonly base?: string;
  readonly facility?: string;
  readonly airspace?: string;
  readonly faaId?: string;
  readonly poc?: string;
  readonly reason?: string;
  readonly county?: string;
  readonly state?: string;

  constructor(values: INsufrFullTime) {
    this.proponent = values.proponent;
    this.branch = values.branch;
    this.base = values.base;
    this.facility = values.facility;
    this.airspace = values.airspace;
    this.faaId = values.faaId;
    this.poc = values.poc;
    this.reason = values.reason;
    this.county = values.county;
    this.state = values.state;
  }
}

export class NsufrFullTimeUtil implements BaseModelDeserializer<TNsufrFullTime, NsufrFullTime> {
  deserialize(raw: unknown): Result<NsufrFullTime> {
    if (isEmpty(raw)) return fail('No data supplied for national security UAS flight restriction');
    if (!isObject(raw)) return fail('Invalid data supplied for national security UAS flight restriction');

    let proponent: undefined | string = undefined;
    if (('PROPONENT' in raw) && !isEmpty(raw.PROPONENT)) {
      if (typeof raw.PROPONENT !== 'string') return fail('Invalid NSUFR proponent value');
      proponent = raw.PROPONENT;
    }

    let branch: undefined | string = undefined;
    if (('BRANCH' in raw) && !isEmpty(raw.BRANCH)) {
      if (typeof raw.BRANCH !== 'string') return fail('Invalid NSUFR branch value');
      branch = raw.BRANCH;
    }

    let base: undefined | string = undefined;
    if (('BASE' in raw) && !isEmpty(raw.BASE)) {
      if (typeof raw.BASE !== 'string') return fail('Invalid NSUFR base value');
      base = raw.BASE;
    }

    let facility: undefined | string = undefined;
    if (('FACILITY' in raw) && !isEmpty(raw.FACILITY)) {
      if (typeof raw.FACILITY !== 'string') return fail('Invalid NSUFR facility value');
      facility = raw.FACILITY;
    }

    let airspace: undefined | string = undefined;
    if (('AIRSPACE' in raw) && !isEmpty(raw.AIRSPACE)) {
      if (typeof raw.AIRSPACE !== 'string') return fail('Invalid NSUFR airspace value');
      airspace = raw.AIRSPACE;
    }

    let faaId: string | undefined = undefined;
    if (('FAA_ID' in raw) && !isEmpty(raw.FAA_ID)) {
      if (typeof raw.FAA_ID !== 'string') return fail('Invalid NSUFR FAA ID value');
      faaId = raw.FAA_ID;
    }

    let poc: string | undefined = undefined;
    if (('POC' in raw) && !isEmpty(raw.POC)) {
      if (typeof raw.POC !== 'string') return fail('Invalid NSUFR POC value');
      poc = raw.POC;
    }

    let reason: undefined | string = undefined;
    if (('REASON' in raw) && !isEmpty(raw.REASON)) {
      if(typeof raw.REASON !== 'string') return fail('Invalid NSUFR reason value');
      reason = raw.REASON;
    }

    let county: undefined | string = undefined;
    if (('COUNTY' in raw) && !isEmpty(raw.COUNTY)) {
      if (typeof raw.COUNTY !== 'string') return fail('Invalid NSUFR county value');
      county = raw.COUNTY;
    }

    let state: undefined | string = undefined;
    if (('STATE' in raw) && !isEmpty(raw.STATE)) {
      if (typeof raw.STATE !== 'string') return fail('Invalid NSUFR state value');
      state = raw.STATE;
    }

    return some(new NsufrFullTime({
      proponent,
      branch,
      base,
      facility,
      airspace,
      faaId,
      poc,
      reason,
      county,
      state
    }));
  }

  deserializeArray(raw: unknown): Result<NsufrFullTime[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for national security UAS flight restriction array');
    const results: NsufrFullTime[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_NSUFR_FULL_TIME_UTIL = new NsufrFullTimeUtil();
