import {BaseModel, BaseModelDeserializer} from '../common';
import {fail, isEmpty, isObject, Result, some} from '../../utils';

export interface IAirport {
  readonly name?: string;
  readonly servCity?: string;
  readonly state?: string;
  readonly country?: string;
}

export interface TAirport {
  readonly NAME?: string;
  readonly SERVCITY?: string;
  readonly STATE?: string;
  readonly COUNTRY?: string;
}

export class Airport implements IAirport, BaseModel {
  readonly name?: string;
  readonly servCity?: string;
  readonly state?: string;
  readonly country?: string;

  constructor(values: IAirport) {
    this.name = values.name;
    this.servCity = values.servCity;
    this.state = values.state;
    this.country = values.country;
  }
}

export class AirportUtil implements BaseModelDeserializer<TAirport, Airport>{
  deserialize(raw: unknown): Result<Airport> {
    if (isEmpty(raw)) return fail('No data supplied for airport');
    if (!isObject(raw)) return fail('Invalid data supplied for airport');

    let name: string | undefined = undefined;
    if (('NAME' in raw) && !isEmpty(raw.NAME)) {
      if (typeof raw.NAME !== 'string') return fail('Invalid airport name value');
      name = raw.NAME;
    }

    let city: string | undefined = undefined;
    if (('SERVCITY' in raw) && !isEmpty(raw.SERVCITY)) {
      if (typeof raw.SERVCITY !== 'string') return fail('Invalid city value');
      city = raw.SERVCITY;
    }

    let state: string | undefined = undefined;
    if (('STATE' in raw) && !isEmpty(raw.STATE)) {
      if (typeof raw.STATE !== 'string') return fail ('Invalid state value');
      state = raw.STATE;
    }

    let country: string | undefined = undefined;
    if (('COUNTRY' in raw) && !isEmpty(raw.COUNTRY)) {
      if (typeof raw.COUNTRY !== 'string') return fail('Invalid country value');
      country = raw.COUNTRY;
    }

    return some(new Airport({
      name: name,
      servCity: city,
      state: state,
      country: country
    }));
  }

  deserializeArray(raw: unknown): Result<Airport[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for airport array');
    const results: Airport[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_AIRPORT_UTIL = new AirportUtil();
