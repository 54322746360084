import {ModuleWithProviders, NgModule, Provider, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {environment} from '../../../environments/environment';
import {AuthService} from './auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {SpringAuthInterceptor} from './spring-auth-interceptor.service';
import {UvrService} from './uvr.service';
import {SettingsService} from './settings.service';
import {OffNominalReportService} from './off-nominal-report.service';
import {OperationService} from './operation.service';
import {CurrentUserService} from './current-user.service';
import {ErrorService} from './error.service';
import {MockUvrService} from './mock/mock-uvr.service';
import {MockOffNominalReportService} from './mock/mock-off-nominal-report.service';
import {RestAuthService} from './rest/rest-auth.service';
import {RestUvrService} from './rest/rest-uvr.service';
import {RestOffNominalReportService} from './rest/rest-off-nominal-report.service';
import {RestOperationService} from './rest/rest-operation.service';
import {PlatformService} from './platform.service';
import {RestPlatformService} from './rest/rest-platform.service';
import {ContactService} from './contact.service';
import {RestContactService} from './rest/rest-contact.service';
import {UserMessageService} from './user-message.service';
import {RestUserMessageService} from './rest/rest-user-message.service';
import {ConstraintService} from './constraint.service';
import {RestConstraintService} from './rest/rest-constraint.service';
import {ConstraintTypeService} from './constraint-type.service';
import {MockConstraintTypeService} from './mock/mock-constraint-type.service';
import {EnvMapConfigProviderService} from './env-map-config-provider.service';
import {AbstractMapConfigProvider} from '@ax/ax-angular-map-common';
import {LeafletMapService} from '@ax/ax-angular-map-leaflet';
import {ColorService} from './color.service';
import {EnvColorService} from './env-color.service';
import {PermissionService} from '../permissions/service/permission.service';
import {MockPermissionService} from './mock-permission.service';
import {AdminUserService} from './admin/admin-user.service';
import {RestAdminUserService} from './admin/rest/rest-admin-user.service';
import {UserService} from './user.service';
import {RestUserService} from './rest/rest-user.service';
import {UserSettingsService} from './user-settings.service';
import {RestSettingsService} from './rest/rest-settings.service';
import {RestUserSettingsService} from './rest/rest-user-settings.service';
import {OperationDraftService} from './operation-draft.service';
import {RestOperationDraftService} from './rest/rest-operation-draft.service';
import {AltitudeService} from './altitude.service';
import {RestAltitudeService} from './rest/rest-altitude.service';
import {AltitudeUtilService} from '../model/utm/altitude-util.service';
import {CesiumDrawerUtilService} from '../cesium/common/cesium-drawer-util.service';
import {OperatorIntentService} from './operator-intent.service';
import {RestOperatorIntentService} from './rest/rest-operator-intent.service';
import {ApprovalService} from './approval.service';
import {RestApprovalService} from './rest/rest-approval.service';
import {TelemetryStatusService} from './telemetry-status.service';
import {RestTelemetryStatusService} from './rest/rest-telemetry-status.service';
import {OrganizationService} from './organization.service';
import {RestOrganizationService} from './rest/rest-organization.service';
import {AirspaceService} from './airspace.service';
import {RestAirspaceService} from './rest/rest-airspace.service';
import {LaancService} from './laanc.service';
import {RestLaancService} from './rest/rest-laanc.service';
import {StompService} from "./stomp.service";


interface AppProviders {
  authService: Type<AuthService>;
  uvrService: Type<UvrService>;
  settingsService: Type<SettingsService>;
  offNominalService: Type<OffNominalReportService>;
  operationService: Type<OperationService>;
  platformService: Type<PlatformService>;
  contactService: Type<ContactService>;
  userMessageService: Type<UserMessageService>;
  constraintService: Type<ConstraintService>;
  constraintTypeService: Type<ConstraintTypeService>;
  adminUserService: Type<AdminUserService>;
  userService: Type<UserService>;
  userSettingsService: Type<UserSettingsService>;
  operationDraftService: Type<OperationDraftService>;
  altitudeService: Type<AltitudeService>;
  altitudeUtilService: Type<AltitudeUtilService>;
  cesiumDrawerUtilService: Type<CesiumDrawerUtilService>;
  operatorIntentService: Type<OperatorIntentService>;
  approvalsService: Type<ApprovalService>;
  telemetryStatusService: Type<TelemetryStatusService>;
  airspaceService: Type<AirspaceService>;
  laancService: Type<LaancService>;
}

const providers: Provider[] = [
  {provide: HTTP_INTERCEPTORS, useClass: SpringAuthInterceptor, multi: true},
  {provide: SpringAuthInterceptor, useClass: SpringAuthInterceptor},
  {provide: ErrorService, useValue: new ErrorService()},
  CurrentUserService
];

let appProviders: AppProviders;

if (environment.production) {
  appProviders = {
    authService: RestAuthService,
    uvrService: RestUvrService,
    settingsService: RestSettingsService,
    offNominalService: RestOffNominalReportService,
    operationService: RestOperationService,
    platformService: RestPlatformService,
    contactService: RestContactService,
    userMessageService: RestUserMessageService,
    constraintService: RestConstraintService,
    constraintTypeService: MockConstraintTypeService,
    adminUserService: RestAdminUserService,
    userService: RestUserService,
    userSettingsService: RestUserSettingsService,
    operationDraftService: RestOperationDraftService,
    altitudeService: RestAltitudeService,
    altitudeUtilService: AltitudeUtilService,
    cesiumDrawerUtilService: CesiumDrawerUtilService,
    operatorIntentService: RestOperatorIntentService,
    approvalsService: RestApprovalService,
    telemetryStatusService: RestTelemetryStatusService,
    airspaceService: RestAirspaceService,
    laancService: RestLaancService
  };
} else {
  appProviders = {
    authService: RestAuthService,
    uvrService: MockUvrService,
    settingsService: RestSettingsService,
    offNominalService: MockOffNominalReportService,
    operationService: RestOperationService,
    platformService: RestPlatformService,
    contactService: RestContactService,
    userMessageService: RestUserMessageService,
    constraintService: RestConstraintService,
    constraintTypeService: MockConstraintTypeService,
    adminUserService: RestAdminUserService,
    userService: RestUserService,
    userSettingsService: RestUserSettingsService,
    operationDraftService: RestOperationDraftService,
    altitudeService: RestAltitudeService,
    altitudeUtilService: AltitudeUtilService,
    cesiumDrawerUtilService: CesiumDrawerUtilService,
    operatorIntentService: RestOperatorIntentService,
    approvalsService: RestApprovalService,
    telemetryStatusService: RestTelemetryStatusService,
    airspaceService: RestAirspaceService,
    laancService: RestLaancService
  };
  providers.push(
    {provide: AuthService, useClass: RestAuthService},
    {provide: UvrService, useClass: MockUvrService},
    {provide: SettingsService, useClass: RestSettingsService},
    {provide: OffNominalReportService, useClass: MockOffNominalReportService},
    {provide: OperationService, useClass: RestOperationService},
    {provide: PlatformService, useClass: RestPlatformService},
    {provide: ApprovalService, useClass: RestApprovalService}
  );
}
// const envService = appProviders.environmentService.
providers.push(
  {provide: AuthService, useClass: appProviders.authService},
  {provide: UvrService, useClass: appProviders.uvrService},
  {provide: SettingsService, useClass: appProviders.settingsService}, // useValue:envService},
  {provide: OffNominalReportService, useClass: appProviders.offNominalService},
  {provide: OperationService, useClass: appProviders.operationService},
  {provide: PlatformService, useClass: appProviders.platformService},
  {provide: ContactService, useClass: appProviders.contactService},
  {provide: UserMessageService, useClass: appProviders.userMessageService},
  {provide: ConstraintService, useClass: appProviders.constraintService},
  {provide: ConstraintTypeService, useClass: appProviders.constraintTypeService},
  {provide: AbstractMapConfigProvider, useClass: EnvMapConfigProviderService},
  {provide: LeafletMapService, useClass: LeafletMapService},
  {provide: ColorService, useClass: EnvColorService},
  {provide: PermissionService, useClass: MockPermissionService},
  {provide: AdminUserService, useClass: appProviders.adminUserService},
  {provide: UserService, useClass: appProviders.userService},
  {provide: UserSettingsService, useClass: appProviders.userSettingsService},
  {provide: OperationDraftService, useClass: appProviders.operationDraftService},
  {provide: AltitudeService, useClass: appProviders.altitudeService},
  {provide: AltitudeUtilService, useClass: appProviders.altitudeUtilService},
  {provide: AltitudeUtilService, useClass: appProviders.altitudeUtilService},
  {provide: CesiumDrawerUtilService, useClass: appProviders.cesiumDrawerUtilService},
  {provide: OperatorIntentService, useClass: appProviders.operatorIntentService},
  {provide: ApprovalService, useClass: appProviders.approvalsService},
  {provide: TelemetryStatusService, useClass: appProviders.telemetryStatusService},
  {provide: OrganizationService, useClass: RestOrganizationService},
  {provide: AirspaceService, useClass: appProviders.airspaceService},
  {provide: LaancService, useClass: appProviders.laancService},
  {provide:StompService}
);


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: []
})
export class ServicesModule {

  static forRoot() {
    return {
      ngModule: ServicesModule,
      providers
    };
  }
}
