import {Injectable} from '@angular/core';
import {ColorConfig, ColorConfigCollection, ColorId, ColorService} from './color.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import tinycolor, {ColorInput} from 'tinycolor2';
import {state} from '../model/gen/utm';

const defaultColorConfig = (() => {
  const tmp = new Map<ColorId, ColorConfig>();
  const addConfig = (id: ColorId, fill: ColorInput, outline?: ColorInput) => {
    tmp.set(id, {
      id,
      fill: tinycolor(fill),
      outline: outline ? tinycolor(outline) : null
    });
  };

  const DEFAULT_ALPHA = 0.3;
  //disable formatting for this section: https://stackoverflow.com/a/19492318
  // @formatter:off
  addConfig('default',                  {r:   0, g: 255, b:   0, a:           0.6}, {r:   0, g: 255, b:   0, a: 1.0});
  addConfig('operation',                {r:   0, g:   0, b: 255, a: DEFAULT_ALPHA}, {r:   0, g:   0, b: 255, a: 1.0});
  addConfig('operation-proposed',       {r:  12, g: 144, b: 150, a: DEFAULT_ALPHA}, {r:  12, g: 144, b: 150, a: 1.0});
  addConfig('operation-accepted',       {r:   0, g:   0, b: 255, a: DEFAULT_ALPHA}, {r:   0, g:   0, b: 255, a: 1.0});
  addConfig('operation-active',         {r:   0, g: 255, b:   0, a: DEFAULT_ALPHA}, {r:   0, g: 255, b:   0, a: 1.0});
  addConfig('operation-closed',         {r: 128, g: 128, b: 128, a: DEFAULT_ALPHA}, {r: 128, g: 128, b: 128, a: 1.0});
  addConfig('operation-ended',          {r: 128, g: 128, b: 128, a: DEFAULT_ALPHA}, {r: 128, g: 128, b: 128, a: 1.0});
  addConfig('operation-non-conforming', {r: 255, g: 255, b:   0, a: DEFAULT_ALPHA}, {r: 255, g: 255, b:   0, a: 1.0});
  addConfig('operation-contingent',     {r: 255, g: 128, b:   0, a: DEFAULT_ALPHA}, {r: 255, g: 128, b:   0, a: 1.0});
  addConfig('operation-rejected',       {r: 128, g: 128, b: 128, a: DEFAULT_ALPHA}, {r: 128, g: 128, b: 128, a: 1.0});
  addConfig('constraint',               {r: 255, g:   0, b:   0, a: DEFAULT_ALPHA}, {r: 255, g:   0, b:   0, a: 1.0});
  addConfig('constraint-accepted',      {r: 255, g:   0, b:   0, a: DEFAULT_ALPHA}, {r: 255, g:   0, b:   0, a: 1.0});
  addConfig('constraint-active',        {r: 255, g:   0, b:   0, a: DEFAULT_ALPHA}, {r: 255, g:   0, b:   0, a: 1.0});
  addConfig('constraint-ended',         {r: 128, g: 128, b: 128, a: DEFAULT_ALPHA}, {r: 128, g: 128, b: 128, a: 1.0});
  // @formatter:on
  return tmp;
})();

@Injectable({
  providedIn: 'root'
})
export class EnvColorService extends ColorService {
  private colorConfig: BehaviorSubject<ColorConfigCollection>;

  constructor() {
    super();
    this.colorConfig = new BehaviorSubject<ColorConfigCollection>(defaultColorConfig);
  }

  private static parseSetting(value: string): ColorConfigCollection {
    const tmp = JSON.parse(value);
    const ret = new Map<ColorId, ColorConfig>();

    for (const key of tmp) {
      const val = tmp[key];
      if (!val.fill) {
        console.error(`Failing to load color id ${key}`);
        continue;
      }
      ret.set(key, {
        id: key,
        fill: tinycolor(val.fill),
        outline: val.outline ? tinycolor(val.outline) : null
      });

    }

    return ret;
  }

  hasColor(id: string): Observable<boolean> {
    return this.colorConfig.pipe(map(tmp => tmp.has(id)));
  }

  getColorForId(id: ColorId, useDefault = true): Observable<ColorConfig | null> {
    return this.colorConfig.pipe(map(tmp => {
      if (tmp.has(id)) {
        return tmp.get(id);
      } else if (useDefault) {
        return defaultColorConfig.has(id) ? defaultColorConfig.get(id) : defaultColorConfig.get('default');
      }
      return null;
    }), map(config => {
      if (!config) {
        return null;
      }
      return {
        id: config.id,
        fill: config.fill.clone(),
        outline: config.outline ? config.outline.clone() : config.outline
      };
    }));
  }

  getColorForIdImmediate(id: ColorId, useDefault: boolean): ColorConfig | null {
    const tmp = this.colorConfig.value;
    if (tmp.has(id)) {
      return tmp.get(id);
    } else if (useDefault) {
      return defaultColorConfig.has(id) ? defaultColorConfig.get(id) : defaultColorConfig.get('default');
    }
    return null;
  }

  getIdForState(opState: state): ColorId {
    switch (opState) {
      case state.PROPOSED:
        return 'operation-proposed';
      case state.ACCEPTED:
        return 'operation-accepted';
      case state.ACTIVATED:
        return 'operation-active';
      case state.CLOSED:
        return 'operation-closed';
      case state.NONCONFORMING:
        return 'operation-non-conforming';
      case state.ROGUE:
        return 'operation-contingent';
      case state.REJECTED:
        return 'operation-rejected';
      default:
        return 'default';
    }
  }
}
