import {BaseModel, BaseModelDeserializer} from '../common';
import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {AirspaceClassType, DEFAULT_AIRSPACE_CLASS_TYPE_UTIL} from './enums';

export interface IClassAirspace {
  readonly name?: string;
  readonly class?: AirspaceClassType;
  readonly city?: string;
  readonly state?: string;
  readonly country?: string;
}

export interface TClassAirspace {
  readonly NAME?: string;
  readonly CLASS?: AirspaceClassType;
  readonly CITY?: string;
  readonly STATE?: string;
  readonly COUNTRY?: string;
}

export class ClassAirspace implements IClassAirspace, BaseModel {
  readonly name?: string;
  readonly class?: AirspaceClassType;
  readonly city?: string;
  readonly state?: string;
  readonly country?: string;

  constructor(values: IClassAirspace) {
    this.name = values.name;
    this.class = values.class;
    this.city = values.city;
    this.state = values.state;
    this.country = values.country;
  }
}

export class ClassAirspaceUtil implements BaseModelDeserializer<TClassAirspace, ClassAirspace> {
  deserialize(raw: unknown): Result<ClassAirspace> {
    if (isEmpty(raw)) return fail('No data supplied for class airspace');
    if (!isObject(raw)) return fail('Invalid data supplied for class airspace');

    let name: string | undefined = undefined;
    if (('NAME' in raw) && !isEmpty(raw.NAME)) {
      if (typeof raw.NAME !== 'string') return fail('Invalid class airspace name value');
      name = raw.NAME;
    }

    let airspaceClassType: AirspaceClassType | undefined = undefined;
    if (('CLASS' in raw) && !isEmpty(raw.CLASS)) {
      const classType = DEFAULT_AIRSPACE_CLASS_TYPE_UTIL.deserialize(raw.CLASS);
      if (classType.type === 'error') return fail(classType.message);
      airspaceClassType = classType.value;
    }

    let city: string | undefined = undefined;
    if (('CITY' in raw) && !isEmpty(raw.CITY)) {
      if (typeof raw.CITY !== 'string') return fail('Invalid class airspace city value');
      city = raw.CITY;
    }

    let state: string | undefined = undefined;
    if (('STATE' in raw) && !isEmpty(raw.STATE)) {
      if (typeof raw.STATE !== 'string') return fail('Invalid class airspace state value');
      state = raw.STATE;
    }

    let country: string | undefined = undefined;
    if (('COUNTRY' in raw) && !isEmpty(raw.COUNTRY)) {
      if (typeof raw.COUNTRY !== 'string') return fail('Invalid class airspace country value');
      country = raw.COUNTRY;
    }

    return some(new ClassAirspace({
      name: name,
      class: airspaceClassType,
      city: city,
      state: state,
      country: country
    }));
  }

  deserializeArray(raw: unknown): Result<ClassAirspace[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for class airspace array');
    const results: ClassAirspace[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_CLASS_AIRSPACE_UTIL = new ClassAirspaceUtil();
