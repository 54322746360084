import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {LaancService} from '../laanc.service';
import {Observable} from 'rxjs';
import {
  DEFAULT_LAANC_CHECK_REQUEST_UTIL,
  LaancCheckRequest,
  TLaancCheckRequest
} from '../../model/laanc/LaancCheckRequest';
import {DEFAULT_LAANC_CHECK_RESPONSE_UTIL, LaancCheckResponse} from '../../model/laanc/LaancCheckResponse';
import {
  AirspaceDataType,
  DataSyncListResponse,
  DEFAULT_AVAILABLE_DATA_SETS_UTIL,
  DEFAULT_DATA_SYNC_LIST_RESPONSE_UTIL,
  DEFAULT_LAANC_OPERATOR_ACKNOWLEDGEMENT_RESPONSE_UTIL,
  LaancDataSetsResponse,
  LaancOperatorAcknowledgementResponse,
  Result
} from '@ax-uss-ui/common';
import {map} from 'rxjs/operators';
import {
  DEFAULT_LAANC_SUBMISSION_ENTRY_UTIL,
  DEFAULT_LAANC_SUBMISSIONS_SEARCH_RESPONSE_UTIL,
  LaancSubmissionEntry,
  LaancSubmissionsSearchResponse
} from '../../model/laanc/LaancSubmissionEntry';

@Injectable({
  providedIn: 'root'
})
export class RestLaancService extends LaancService {
  private http = inject(HttpClient);
  private baseLaancUrl = `${environment.baseUrl}/operator/laanc`;

  submitLaancCheck(request: LaancCheckRequest): Observable<Result<LaancCheckResponse>> {
    const transport: TLaancCheckRequest = DEFAULT_LAANC_CHECK_REQUEST_UTIL.serialize(request);
    return this.http.post(`${this.baseLaancUrl}/check`, transport)
      .pipe(map(DEFAULT_LAANC_CHECK_RESPONSE_UTIL.deserialize));
  }

  getLaancSubmissions(operationId?: string): Observable<Result<LaancSubmissionsSearchResponse>> {
    const params = operationId ? {operationId} : {};
    return this.http.get(`${this.baseLaancUrl}/authorizations`, {params})
      .pipe(map(data => DEFAULT_LAANC_SUBMISSIONS_SEARCH_RESPONSE_UTIL.deserialize(data)));
  }

  getLaancSubmission(referenceCode: string): Observable<Result<LaancSubmissionEntry>> {
    return this.http.get(`${this.baseLaancUrl}/authorizations/${referenceCode}`)
      .pipe(map(data => DEFAULT_LAANC_SUBMISSION_ENTRY_UTIL.deserialize(data)));
  }

  acknowledgeInvalidCancel(referenceCode: string): Observable<Result<LaancOperatorAcknowledgementResponse>> {
    return this.http.post(`${this.baseLaancUrl}/ackinvalidcancel/${referenceCode}`, null)
      .pipe(map(res => DEFAULT_LAANC_OPERATOR_ACKNOWLEDGEMENT_RESPONSE_UTIL.deserialize(res)));
  }

  acknowledgeRescinded(referenceCode: string): Observable<Result<LaancOperatorAcknowledgementResponse>> {
    return this.http.post(`${this.baseLaancUrl}/ackrescinded/${referenceCode}`, null)
      .pipe(map(res => DEFAULT_LAANC_OPERATOR_ACKNOWLEDGEMENT_RESPONSE_UTIL.deserialize(res)));
  }

  cancelAllSubmissionsForOperation(operationId: string): Observable<Result<LaancOperatorAcknowledgementResponse>> {
    return this.http.post(`${this.baseLaancUrl}/cancelall/${operationId}`, null)
      .pipe(map(res => DEFAULT_LAANC_OPERATOR_ACKNOWLEDGEMENT_RESPONSE_UTIL.deserialize(res)));
  }

  deleteSubmission(referenceCode: string): Observable<Result<LaancOperatorAcknowledgementResponse>> {
    return this.http.post(`${this.baseLaancUrl}/delete/${referenceCode}`, null)
      .pipe(map(res => DEFAULT_LAANC_OPERATOR_ACKNOWLEDGEMENT_RESPONSE_UTIL.deserialize(res)));
  }

  getDataSync(availableOnly = false): Observable<Result<DataSyncListResponse>> {
    return this.http.get(`${this.baseLaancUrl}/datasync`, {params: {availableOnly}})
      .pipe(map(res => DEFAULT_DATA_SYNC_LIST_RESPONSE_UTIL.deserialize(res)));
  }

  updateActiveDataSet(dataType: AirspaceDataType, datasyncId: string): Observable<boolean> {
    return this.http.post(`${this.baseLaancUrl}/datasync`, {dataType, datasyncId})
      .pipe(map(res => true));
  }

  getActiveDataSets(): Observable<Result<DataSyncListResponse>> {
    return this.http.get(`${this.baseLaancUrl}/datasync/active`)
      .pipe(map(res => DEFAULT_DATA_SYNC_LIST_RESPONSE_UTIL.deserialize(res)));
  }

  getAvailableDataSets(): Observable<Result<LaancDataSetsResponse>> {
    return this.http.get(`${this.baseLaancUrl}/datasync/available`)
      .pipe(map(res => DEFAULT_AVAILABLE_DATA_SETS_UTIL.deserialize(res)));
  }
}
