import {inject, Injectable} from '@angular/core';
import {AirspaceService} from '../airspace.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {CZMLPacket} from '@ax-uss-ui/common';

@Injectable({
  providedIn: 'root'
})
export class RestAirspaceService extends AirspaceService {
  private http = inject(HttpClient);
  private baseAirspaceUrl = `${environment.baseUrl}/operator/airspace`;

  getUasFacilityMap(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/uasfm`, {params: {envelope: envelope.join(',')}});
  }

  getClassAirspace(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/classairspace`, {params: {envelope: envelope.join(',')}});
  }

  getSpecialUseAirspace(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/sua`, {params: {envelope: envelope.join(',')}});
  }

  getAirports(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/airport`, {params: {envelope: envelope.join(',')}});
  }

  getStadiums(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/stadium`, {params: {envelope: envelope.join(',')}});
  }

  getNsufrs(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/nsufr`, {params: {envelope: envelope.join(',')}});
  }

  getWashingtonDcFrz(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/dcfrz`, {params: {envelope: envelope.join(',')}});
  }

  getTfrs(envelope: number[]): Observable<CZMLPacket[]> {
    return this.http.get<CZMLPacket[]>(`${this.baseAirspaceUrl}/tfr`, {params: {envelope: envelope.join(',')}});
  }

}
