import {DateTime} from 'luxon';
import {BaseModel, BaseModelDeserializer} from '../common';
import {fail, isEmpty, isObject, Result, some} from '../../utils';

export interface INsufrPartTime {
  readonly proponent?: string;
  readonly branch?: string;
  readonly base?: string;
  readonly facility?: string;
  readonly airspace?: string;
  readonly faaId?: string;
  readonly poc?: string;
  readonly reason?: string;
  readonly state?: string;
  readonly county?: string;
  readonly adviseAuth?: string;
  readonly adviseNote?: string;
  readonly alertTime?: DateTime;
  readonly activeTime?: DateTime;
  readonly endTime?: DateTime;
}

export interface TNsufrPartTime {
  readonly PROPONENT?: string;
  readonly BRANCH?: string;
  readonly BASE?: string;
  readonly FACILITY?: string;
  readonly AIRSPACE?: string;
  readonly FAA_ID?: string;
  readonly POC?: string;
  readonly REASON?: string;
  readonly STATE?: string;
  readonly COUNTY?: string;
  readonly ADVISEAUTH?: string;
  readonly ADVISENOTE?: string;
  readonly ALERTTIME?: string;
  readonly ACTIVETIME?: string;
  readonly ENDTIME?: string;
}

export class NsufrPartTime implements INsufrPartTime, BaseModel {
  readonly proponent?: string;
  readonly branch?: string;
  readonly base?: string;
  readonly facility?: string;
  readonly airspace?: string;
  readonly faaId?: string;
  readonly poc?: string;
  readonly reason?: string;
  readonly state?: string;
  readonly county?: string;
  readonly adviseAuth?: string;
  readonly adviseNote?: string;
  readonly alertTime?: DateTime;
  readonly activeTime?: DateTime;
  readonly endTime?: DateTime;

  constructor(values: INsufrPartTime) {
    this.proponent = values.proponent;
    this.branch = values.branch;
    this.base = values.base;
    this.facility = values.facility;
    this.airspace = values.airspace;
    this.faaId = values.faaId;
    this.poc = values.poc;
    this.reason = values.reason;
    this.state = values.state;
    this.county = values.county;
    this.adviseAuth = values.adviseAuth;
    this.adviseNote = values.adviseNote;
    this.alertTime = values.alertTime;
    this.activeTime = values.activeTime;
    this.endTime = values.endTime;
  }
}

export class NsufrPartTimeUtil implements BaseModelDeserializer<TNsufrPartTime, NsufrPartTime> {
  deserialize(raw: unknown): Result<NsufrPartTime> {
    if (isEmpty(raw)) return fail('No data supplied for national security UAS flight restriction');
    if (!isObject(raw)) return fail('Invalid data supplied for national security UAS flight restriction');

    let proponent: undefined | string = undefined;
    if (('PROPONENT' in raw) && !isEmpty(raw.PROPONENT)) {
      if (typeof raw.PROPONENT !== 'string') return fail('Invalid NSUFR proponent value');
      proponent = raw.PROPONENT;
    }

    let branch: undefined | string = undefined;
    if (('BRANCH' in raw) && !isEmpty(raw.BRANCH)) {
      if (typeof raw.BRANCH !== 'string') return fail('Invalid NSUFR branch value');
      branch = raw.BRANCH;
    }

    let base: undefined | string = undefined;
    if (('BASE' in raw) && !isEmpty(raw.BASE)) {
      if (typeof raw.BASE !== 'string') return fail('Invalid NSUFR base value');
      base = raw.BASE;
    }

    let facility: undefined | string = undefined;
    if (('FACILITY' in raw) && !isEmpty(raw.FACILITY)) {
      if (typeof raw.FACILITY !== 'string') return fail('Invalid NSUFR facility value');
      facility = raw.FACILITY;
    }

    let airspace: undefined | string = undefined;
    if (('AIRSPACE' in raw) && !isEmpty(raw.AIRSPACE)) {
      if (typeof raw.AIRSPACE !== 'string') return fail('Invalid NSUFR airspace value');
      airspace = raw.AIRSPACE;
    }

    let faaId: string | undefined = undefined;
    if (('FAA_ID' in raw) && !isEmpty(raw.FAA_ID)) {
      if (typeof raw.FAA_ID !== 'string') return fail('Invalid NSUFR FAA ID value');
      faaId = raw.FAA_ID;
    }

    let poc: string | undefined = undefined;
    if (('POC' in raw) && !isEmpty(raw.POC)) {
      if (typeof raw.POC !== 'string') return fail('Invalid NSUFR POC value');
      poc = raw.POC;
    }

    let reason: undefined | string = undefined;
    if (('REASON' in raw) && !isEmpty(raw.REASON)) {
      if(typeof raw.REASON !== 'string') return fail('Invalid NSUFR reason value');
      reason = raw.REASON;
    }

    let state: undefined | string = undefined;
    if (('STATE' in raw) && !isEmpty(raw.STATE)) {
      if (typeof raw.STATE !== 'string') return fail('Invalid NSUFR state value');
      state = raw.STATE;
    }

    let county: undefined | string = undefined;
    if (('COUNTY' in raw) && !isEmpty(raw.COUNTY)) {
      if (typeof raw.COUNTY !== 'string') return fail('Invalid NSUFR county value');
      county = raw.COUNTY;
    }

    let adviseAuth: string | undefined = undefined;
    if (('ADVISEAUTH' in raw) && !isEmpty(raw.ADVISEAUTH)) {
      if (typeof raw.ADVISEAUTH !== 'string') return fail('Invalid NSUFR advise authority value');
      adviseAuth = raw.ADVISEAUTH;
    }

    let adviseNote: string | undefined = undefined;
    if (('ADVISENOTE' in raw) && !isEmpty(raw.ADVISENOTE)) {
      if (typeof raw.ADVISENOTE !== 'string') return fail('Invalid NSUFR advise note value');
      adviseNote = raw.ADVISENOTE;
    }

    let alertTime: DateTime | undefined = undefined;
    if (('ALERTTIME' in raw) && !isEmpty(raw.ALERTTIME)) {
      if (typeof raw.ALERTTIME !== 'string') return fail('Invalid NSUFR alert time value');
      alertTime = DateTime.fromISO(raw.ALERTTIME);
      if (!alertTime.isValid) return fail('Invalid NSUFR alert time value');
    }

    let activeTime: DateTime | undefined = undefined;
    if (('ACTIVETIME' in raw) && !isEmpty(raw.ACTIVETIME)) {
      if (typeof raw.ACTIVETIME !== 'string') return fail('Invalid NSUFR active time value');
      activeTime = DateTime.fromISO(raw.ACTIVETIME);
      if (!activeTime.isValid) return fail('Invalid NSUFR active time value');
    }

    let endTime: DateTime | undefined = undefined;
    if (('ENDTIME' in raw) && !isEmpty(raw.ENDTIME)) {
      if (typeof raw.ENDTIME !== 'string') return fail('Invalid NSUFR end time value');
      endTime = DateTime.fromISO(raw.ENDTIME);
      if (!endTime.isValid) return fail('Invalid NSUFR end time value');
    }


    return some(new NsufrPartTime({
      proponent,
      branch,
      base,
      facility,
      airspace,
      faaId,
      poc,
      reason,
      state,
      county,
      adviseAuth,
      adviseNote,
      alertTime,
      activeTime,
      endTime
    }));
  }

  deserializeArray(raw: unknown): Result<NsufrPartTime[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for part time national security UAS flight restriction array');
    const results: NsufrPartTime[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_NSUFR_PART_TIME_UTIL = new NsufrPartTimeUtil();
