import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {BaseModel, BaseModelDeserializer} from '../common';

export interface TLaancPoc {
  readonly firstName: string;
  readonly lastName: string;
  readonly pocPhoneNumber: string;
}

/**
 * LAANC POC Info
 *
 * @param firstName
 * @param lastName
 * @param pocPhoneNumber
 */
export class LaancPoc implements BaseModel {
  readonly firstName: string;
  readonly lastName: string;
  readonly pocPhoneNumber: string;

  constructor(firstName: string, lastName: string, pocPhoneNumber: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.pocPhoneNumber = pocPhoneNumber
  }
}

export class LaancPocUtil implements BaseModelDeserializer<TLaancPoc, LaancPoc> {
  deserialize(raw: unknown): Result<LaancPoc> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC POC');
    if (!isObject(raw)) return fail('Invalid type for LAANC POC');

    if (!('firstName' in raw) || isEmpty(raw.firstName)) return fail('No LAANC POC first name value');
    if (typeof raw.firstName !== 'string') return fail('Invalid LAANC POC first name value');

    if (!('lastName' in raw) || isEmpty(raw.lastName)) return fail('No LAANC POC last name value');
    if (typeof raw.lastName !== 'string') return fail('Invalid LAANC POC last name value');

    if (!('pocPhoneNumber' in raw) || isEmpty(raw.pocPhoneNumber)) return fail('No LAANC POC phone number value');
    if (typeof raw.pocPhoneNumber !== 'string') return fail('Invalid LAANC POC phone number value');

    return some(new LaancPoc(raw.firstName, raw.lastName, raw.pocPhoneNumber));
  }
}

export const DEFAULT_LAANC_POC_UTIL = new LaancPocUtil();
